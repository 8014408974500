import React from 'react';

import {translate} from 'react-admin';

import ToggleOn from '@material-ui/icons/ToggleOn';
import ToggleOff from '@material-ui/icons/ToggleOff';
import IconButton from '@material-ui/core/IconButton';
import {postRequest} from "../../utils/http";
import {NotificationManager} from "react-notifications";

class EntityPlanDisableButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
    };
  }

  toggle = () => {
    const { record, translate } = this.props;
    this.setState({
      processing: true,
    });
    postRequest({
      endpoint: `/entity-plan/${record.id}/toggle`,
      callback: (err) => {
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
        this.setState({
          processing: false,
        });
      },
    });
  };

  render() {
    const { record } = this.props;
    if (!!record.canToggle) {
      const { processing } = this.state;
      return <div>
        <IconButton
          {...this.props}
          disabled={processing}
          size="small"
          onClick={this.toggle}
        >
          {!!record.enabled && <ToggleOff color='secondary' />}
          {!record.enabled && <ToggleOn color='primary' />}
        </IconButton>
      </div>;
    }
    return <div/>;
  }
}

export default translate(EntityPlanDisableButton);
