
import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { Layout, AppBar, UserMenu, MenuItemLink, translate } from 'react-admin';
import Config from '../Config';

const AppUserMenu = translate(({ translate, ...props }) => (
    <UserMenu {...props} label="Menu">
        <MenuItemLink
            to={Config.CONFIGURATION_ROUTE}
            primaryText={translate('app.label.configuration')}
            leftIcon={<SettingsIcon />} />
    </UserMenu>
));

const AdminAppBar = translate(({ translate, ...props }) => (
    <AppBar {...props} userMenu={<AppUserMenu />} />
));

const AppLayout = translate(({ translate, ...props }) => (
    <Layout {...props} appBar={AdminAppBar} />
));

export default AppLayout;
