import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'react-notifications/lib/notifications.css';

import App from './app/App';
import { NotificationContainer } from 'react-notifications';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <div>
        <App />
        <NotificationContainer />
    </div>,
    document.getElementById('root')
);

serviceWorker.unregister();
