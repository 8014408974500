import React from 'react';
import { SaveButton, Toolbar, } from 'react-admin';
import { NotificationManager } from 'react-notifications';
import Autocomplete from '@material-ui/lab/Autocomplete';
import WebIcon from '@material-ui/icons/Web';
import CustomButton from '../widget/CustomButton';
import { getRequest, postRequest } from '../../utils/http';
import AddDocumentsIcon from '@material-ui/icons/AccountBalanceWallet';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import BlackListIcon from '@material-ui/icons/SpeakerNotesOff';

class UserEditToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecting: false,
      addingDocuments: false,
      processing: false,
      documents: 100,
      days: 365,
      userCode: '',
      entities: [],
      entityId: 0,
    };
  }

  fetchEntities() {
    const { record } = this.props;
    getRequest({
      endpoint: `/users/${record.id}/entities`,
      callback: (err, data) => {
        if (err) {
          console.error(err.toString());
        } else {
          const stateData = {entities: data || []};
          if (data.length === 1) {
            stateData['entityId'] = data[0].id;
          }
          this.setState(stateData);
        }
      },
    });
  }

  openDocumentsDialog = () => {
    this.setState({ addingDocuments: true });
  };

  closeDocumentsDialog = () => {
    this.setState({ addingDocuments: false });
  };

  redirectToUser = () => {
    this.fetchEntities();
    this.setState({ redirecting: true });
  };

  closeRedirectToUserDialog = () => {
    this.setState({ redirecting: false });
  };

  doRedirectToUser = () => {
    const { record, translate } = this.props;
    const { userCode, entityId } = this.state;
    if (!userCode) {
      NotificationManager.error('Error', translate('app.messages.userCodeIsRequired'));
      return;
    }
    if (!entityId) {
      NotificationManager.error('Error', translate('app.messages.entitySelectIsRequired'));
      return;
    }
    this.setState({ processing: true });
    postRequest({
      endpoint: `/users/${record.id}/auth-in-web`,
      data: {
        userCode,
        entityId,
      },
      callback: (err, data) => {
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          window.open(data['redirect'], '_blank');
        }
        this.setState({ processing: false });
      },
    });
  };

  addApiPlan = () => {
    const { record, translate } = this.props;
    const { documents, days } = this.state;
    if (!documents || isNaN(documents) || documents <= 0) {
      NotificationManager.error(translate('app.messages.invalidDocuments'), '');
      return;
    }
    if (!days || isNaN(days) || days <= 0) {
      NotificationManager.error(translate('app.messages.invalidDays'), '');
      return;
    }
    this.setState({ processing: true });
    postRequest({
      endpoint: `/users/${record.id}/add-api-documents`,
      data: {
        amount: documents,
        days,
      },
      callback: (err, data) => {
        this.setState({ processing: false, addingDocuments: err });
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          NotificationManager.success(data);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      },
    });
  };

  toggleBlacklist = () => {
    this.setState({ saving: true });
    const { record, translate } = this.props;
    postRequest({
      endpoint: `/users/${record.id}/un-blacklist`,
      data: {},
      callback: (err) => {
        this.setState({ saving: false });
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          NotificationManager.success(translate('app.messages.emailRemovedFromBlacklist') + ' ' + record.id);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      },
    });
  };

  render() {
    const { record, translate } = this.props;
    const { addingDocuments, redirecting, processing, documents, entities, entityId, userCode, days } = this.state;
    const toolbar = (
      <Toolbar {...this.props} >
        <SaveButton />
        <CustomButton
          className="web-user-button"
          icon={<WebIcon />}
          onClick={() => this.redirectToUser()}
        >
          {translate('app.action.logInWeb')}
        </CustomButton>
        {false && <CustomButton
          className="plan-user-button"
          icon={<AddDocumentsIcon />}
          onClick={this.openDocumentsDialog}
        >
          {translate('app.action.addApiDocuments')}
        </CustomButton>}
        {record.emailBlackListed && <CustomButton
          className="set-coupon-reseller-button"
          icon={<BlackListIcon />}
          onClick={this.toggleBlacklist}
        >
          {translate('app.action.toggleBlacklist')}
        </CustomButton>}
      </Toolbar>
    );

    const addDocumentsDialog = (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='lg'
        open={addingDocuments}
        onClose={this.closeDocumentsDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {translate('app.action.addApiDocuments')}
        </DialogTitle>
        {processing && <CircularProgress style={{ margin: '0 auto' }} />}
        <DialogContent>
          <TextField
            disabled={processing}
            variant="outlined"
            style={{ marginLeft: '10px', marginRight: '10px' }}
            margin="dense"
            id="custom-plan-documents"
            label={translate('app.label.documents')}
            type="number"
            value={documents}
            onChange={(event) => {
              this.setState({ documents: parseInt(event.target.value) });
            }}
          />
          <TextField
            disabled={processing}
            variant="outlined"
            margin="dense"
            id="custom-plan-days"
            label={translate('app.label.days')}
            type="number"
            value={days}
            onChange={(event) => {
              this.setState({ days: parseInt(event.target.value) });
            }}
          />
          <Button
            disabled={processing}
            size="small" style={{ marginTop: '8px', marginLeft: '10px' }}
            onClick={this.addApiPlan}
            variant="contained" color="primary">
            {translate('app.action.add')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.closeDocumentsDialog}
            disabled={processing}
            color="secondary">
            {translate('app.action.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );

    const redirectToUserDialog = (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='lg'
        open={redirecting}
        onClose={this.closeRedirectToUserDialog}
        aria-labelledby="form-redirect-dialog-title"
      >
        <DialogTitle id="form-redirect-dialog-title">
          {translate('app.action.loginWeb')}
        </DialogTitle>
        <DialogContent>
          <TextField
            disabled={processing}
            id="user-code"
            label={translate('app.label.code')}
            onChange={(event) => {
              this.setState({ userCode: event.target.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {(entities.length === 0) && <CircularProgress size={20} style={{ marginRight: '10px' }} />}
          {(entities.length > 0) && <Autocomplete
            disabled={processing || entities.length < 2}
            options={entities}
            value={entityId}
            onChange={(_, newValue) => {
              this.setState({
                entityId: !!newValue ? newValue.id : null,
              });
            }}
            getOptionLabel={(option) => {
              const filtered = entities.filter((value) => value.id === option || value.id === option.id);
              if (filtered.length === 1) {
                const entity = filtered[0];
                return `${entity.legalId} - ${entity.email}`;
              }
              return '-';
            }}
            style={{ marginRight: '10px', float: 'left', width: 500 }}
            renderInput={(params) => <TextField {...params} label={translate('app.label.entity')} variant="outlined" />}
          />}
          <Button
            disabled={processing || !entityId || !userCode}
            size="small" style={{ marginTop: '8px', marginLeft: '10px' }}
            onClick={this.doRedirectToUser}
            variant="contained" color="primary">
            {translate('app.action.loginWeb')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.closeRedirectToUserDialog}
            disabled={processing}
            color="secondary">
            {translate('app.action.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );

    return (
      <div>
        {toolbar}
        {addDocumentsDialog}
        {redirectToUserDialog}
      </div>
    );
  }
}

export default UserEditToolbar;
