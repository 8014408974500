import React from 'react';

import {translate} from 'react-admin';

import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Receipt from '@material-ui/icons/Receipt';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomButton from "../widget/CustomButton";
import {userRoleKey} from "../../provider/authProvider";
import SearchIcon from "@material-ui/icons/Search";
import {getRequest, postRequest} from "../../utils/http";
import Config from "../../Config";
import {NotificationManager} from "react-notifications";
import companies, {touwolfCode} from '../../model/companies';

class TransactionInvoiceButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      capturing: false,
      processing: false,
      invoice: null,
      invoiceObj: null,
      company: touwolfCode,
    };
  }

  capture = () => {
    this.setState({
      capturing: true,
    });
  };

  search = () => {
    const { invoice } = this.state;
    if (!invoice) {
      return;
    }
    const { translate } = this.props;
    this.setState({
      processing: true,
    });
    getRequest({
      endpoint: `/documents/${Config.ROOT_ENTITY_ID}/search?type=bill&code=${invoice}`,
      callback: (err, data) => {
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          this.setState({
            invoiceObj: data,
          });
        }
        this.setState({
          processing: false,
        });
      },
    });
  };

  create = () => {
    const { company } = this.state;
    const { record, translate } = this.props;
    this.setState({
      processing: true,
    });
    postRequest({
      endpoint: `/transactions/${record.id}/create-invoice/${company || touwolfCode}`,
      callback: (err) => {
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          this.setState({
            capturing: false,
            invoice: null,
            invoiceObj: null,
          });
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
        this.setState({
          processing: false,
        });
      },
    });
  };

  associate = () => {
    const { invoiceObj } = this.state;
    if (!invoiceObj) {
      return;
    }
    const { record, translate } = this.props;
    this.setState({
      processing: true,
    });
    postRequest({
      endpoint: `/transactions/${record.id}/associate-invoice/${invoiceObj.id}`,
      callback: (err) => {
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          this.setState({
            capturing: false,
            invoice: null,
            invoiceObj: null,
          });
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
        this.setState({
          processing: false,
        });
      },
    });
  };

  render() {
    const { record, translate, large } = this.props;
    const { capturing, processing, invoice, invoiceObj, company } = this.state;
    if ('ADMINISTRATOR_VALIDATOR' !== localStorage.getItem(userRoleKey)
        || !record || !!record.invoiceId) {
      return <div/>;
    }
    let button = large ? <CustomButton
      className="web-validate-button"
      icon={<Receipt />}
      onClick={this.capture}
    >
      {translate('app.action.setInvoice')}
    </CustomButton> : <IconButton
      {...this.props}
      size="small"
      onClick={this.capture}
    >
      <Receipt color='primary' />
    </IconButton>;
    return <div>
      {button}
      <Dialog
        open={capturing}
        onClose={false}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{translate('app.action.setInvoice')}</DialogTitle>
        <DialogContent>
          <TextField
            disabled={processing}
            margin="dense"
            id="invoice"
            value={invoice}
            label={translate('app.label.invoiceCode')}
            onChange={(e) => {
              this.setState({
                invoice: e.target.value,
              });
            }}
            fullWidth
          />
          <div style={{marginTop: '2em'}}></div>
          <InputLabel id="invoice-company-label">
            {translate('app.label.company')}
          </InputLabel>
          <Select
            disabled={processing}
            margin="dense"
            labelId="invoice-company-label"
            id="invoice-company"
            value={company}
            fullWidth
            onChange={(e) => {
              this.setState({
                company: e.target.value,
              });
            }}
          >
            {companies.map((value) => <MenuItem value={value}>{translate(`app.company.${value}`)}</MenuItem>)}
          </Select>
          <div style={{marginTop: '2em'}}></div>
          {(!invoiceObj || !invoiceObj.authorizedAt) && <CustomButton
            disabled={processing}
            className={processing ? 'web-disabled-button' : 'web-invoice-button'}
            icon={<Receipt />}
            onClick={this.create}
          >
            {translate('app.action.createInvoice')}
          </CustomButton>}
          {(invoice && invoice.length > 4) && <CustomButton
            disabled={processing}
            className={processing ? 'web-disabled-button' : 'web-invoice-button'}
            icon={<SearchIcon />}
            onClick={this.search}
          >
            {translate('app.action.search')}
          </CustomButton>}
          {invoiceObj && <div>
            <br/>
            {invoiceObj.authorizedAt && <span>
              {translate('app.label.authorized')}: {invoiceObj.authorizedAt}<br/>
            </span>}
            {!invoiceObj.authorizedAt && <span style={{color: 'red'}}>
              {translate('app.messages.mustSearchAnAuthorizedDocument')}<br/>
            </span>}
            <span>
              {translate('app.label.type')}: {translate('app.document.' + invoiceObj.type)}<br/>
            </span>
            <span>
              {translate('app.label.state')}: {translate('app.state.' + invoiceObj.state)}<br/>
            </span>
            {invoiceObj.authorizedAt && <CustomButton
              className={processing ? 'web-disabled-button' : 'web-invoice-button'}
              disabled={processing}
              icon={<Receipt />}
              onClick={this.associate}>
              {translate('app.action.associateInvoice')}
            </CustomButton>}
          </div>}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={processing}
            onClick={() => {
              this.setState({
                capturing: false,
                processing: false,
              });
            }}
            color="primary">
            {translate('app.action.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>;
  }
}

export default translate(TransactionInvoiceButton);
