import React from 'react';
import {
    translate,
    TabbedShowLayout,
    Tab,
    TextField,
    DateField,
    Show,
} from 'react-admin';

import EntityPlanTitle from './EntityPlanTitle';
import EntityReference from "../entities/EntityReference";

const EntityPlanDetail = ({ ...props }) => (
    <Show title={<EntityPlanTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label='app.label.details'>
                <TextField label='app.label.code' source='code' />
                <TextField label='app.label.name' source='name' />
                <TextField label='app.label.description' source='description' />
                <TextField label='app.label.documents' source='amount' />
                <TextField label='app.label.usedDocuments' source='usedAmount' />
                <DateField
                    label='app.label.created'
                    source='createdAt'
                    options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} />
                <DateField
                    label='app.label.expires'
                    source='expiresAt'
                    options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} />
            </Tab>
            <EntityReference {...props} />
        </TabbedShowLayout>
    </Show>
);

export default translate(EntityPlanDetail);
