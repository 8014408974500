import React from 'react';
import {
    Toolbar,
} from 'react-admin';
import { saveAs } from 'file-saver';
import RefundIcon from '@material-ui/icons/Restore';
import FileIcon from '@material-ui/icons/Description';
import CustomButton from '../widget/CustomButton';
import { NotificationManager } from 'react-notifications';
import {base64toBlob, getRequest, postRequest} from '../../utils/http';
import TransactionValidateButton from "./TransactionValidateButton";
import TransactionInvoiceButton from "./TransactionInvoiceButton";

class TransactionEditToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refunding: false,
    };
  }

  refund = () => {
    const { record, translate } = this.props;
    if (record.state !== 'approved') {
      NotificationManager.error(translate('app.messages.invalidTransactionState'), '');
      return;
    }
    this.setState({ refunding: true });
    postRequest({
      endpoint: `/transactions/${record.id}/refund`,
      data: {},
      callback: (err) => {
        this.setState({ refunding: false });
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      },
    });
  };

  seeFile = () => {
    const { record, translate } = this.props;
    getRequest({
      endpoint: `/transactions/${record.id}/file`,
      data: {},
      callback: (err, json) => {
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else if (!!json) {
          const blob = base64toBlob(json);
          if (!blob) {
            NotificationManager.error(translate('app.messages.noFile'), translate('app.messages.errorHappened'));
          } else {
            saveAs(blob, `transaction-${record.id}`);
          }
        } else {
          NotificationManager.error(translate('app.messages.noFile'), translate('app.messages.errorHappened'));
        }
      },
    });
  };

  render() {
    const { record, translate } = this.props;
    return <Toolbar {...this.props} >
      <CustomButton
        className="web-user-button"
        icon={<RefundIcon />}
        onClick={this.refund}
      >
        {translate('app.action.refund')}
      </CustomButton>
      <TransactionValidateButton large />
      <TransactionInvoiceButton large />
      {(!!record && !!record.fileUrl) && <CustomButton
        className="web-validate-button"
        icon={<FileIcon />}
        onClick={this.seeFile}
      >
        {translate('app.action.seeFile')}
      </CustomButton>}
    </Toolbar>;
  }
}

export default TransactionEditToolbar;
