import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    Responsive,
    ShowButton,
    SimpleList,
    TextField,
    EmailField,
    RefreshButton,
    translate
} from 'react-admin';

import UserFilter from "./UserFilter";

const UsersList = (props) => (
    <List {...props}
          title={'app.label.users'}
          filters={<UserFilter />}
          exporter={false}
          bulkActionButtons={false}
          actions={<RefreshButton label='' />}>
        <Responsive
            small={
                <SimpleList
                    linkType="show"
                    primaryText={record => record.name}
                    secondaryText={record => record.email}
                    tertiaryText={record => new Date(record.createdAt).toLocaleDateString()} />
            }
            medium={
                <Datagrid>
                    <TextField label='app.label.name' source='name' />
                    <EmailField label='app.label.email' source='email' />
                    <DateField
                        label='app.label.created'
                        source='createdAt'
                        options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} />
                    <ShowButton label='' />
                </Datagrid>
            }
        />
    </List>
);

export default translate(UsersList);
