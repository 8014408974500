
import {
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';
import Config from '../Config';

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {

    let options = {
        headers: new Headers({ Accept: 'application/json' })
    };
    const sessionJson = localStorage.getItem(Config.USER_SESSION_KEY);
    if (sessionJson) {
        const userSession = JSON.parse(sessionJson);
        if (userSession && userSession.token) {
            options.headers.set('Authorization', `Bearer ${userSession.token}`);
        }
    }

    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                page: page,
                pageSize: perPage,
                filter: JSON.stringify(toArray(params.filter)),
            };
            return {
                url: `${Config.BASE_URL}/${resource}?${stringify(query)}`,
                options: options
            };
        }
        case GET_MANY: {
            const query = {
                ids: JSON.stringify(params.ids)
            };
            return {
                url: `${Config.BASE_URL}/${resource}?${stringify(query)}`,
                options: options
            };
        }
        case GET_MANY_REFERENCE: {
            let filter = toArray(params.filter);
            filter.push(params.target, String(params.id));
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                page: page,
                pageSize: perPage,
                filter: JSON.stringify(filter),
            };
            return {
                url: `${Config.BASE_URL}/${resource}?${stringify(query)}`,
                options: options
            };
        }
        case GET_ONE:
            return {
                url: `${Config.BASE_URL}/${resource}/${params.id}`,
                options: options
            };
        case UPDATE: {
            options.method = 'POST';
            options.body = JSON.stringify(params.data);
            return {
                url: `${Config.BASE_URL}/${resource}/${params.id}`,
                options: options,
            };
        }
        case CREATE: {
            options.method = 'PUT';
            options.body = JSON.stringify(params.data);
            return {
                url: `${Config.BASE_URL}/${resource}`,
                options: options,
            };
        }
        case DELETE: {
            options.method = 'DELETE';
            return {
                url: `${Config.BASE_URL}/${resource}/${params.id}`,
                options: options,
            };
        }
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};

const toArray = (object) => {
    let array = [];
    Object.keys(object)
        .forEach(key => {
            array.push(key);
            array.push(object[key]);
        });
    return array;
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    const { json } = response;
    switch (type) {
        case GET_LIST:
            return {
                data: json.list,
                total: json.total
            };
        case GET_MANY_REFERENCE:
            return {
                data: json.list,
                total: json.total
            };
        case GET_MANY:
            return {
                data: json.list
            };
        case CREATE:
            return {
                data: { ...params.data, id: json.id }
            };
        default:
            return { data: json };
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} params Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
    const { fetchJson } = fetchUtils;
    const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
    return fetchJson(url, options)
        .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};
