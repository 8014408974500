import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  EmailField,
  ImageField,
  ListButton,
  ReferenceManyField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  translate,
  UPDATE,
} from 'react-admin';
import EntityTitle from './EntityTitle';
import EntityEditToolbar from './EntityEditToolbar';
import dataProvider from '../../provider/dataProvider';
import { NotificationManager } from 'react-notifications';
import EntityPlanDisableButton from '../entity-plans/EntityPlanDisableButton';

class EntityDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { saving: false, };
    this.saveEntity = this.saveEntity.bind(this);
  }

  saveEntity(record) {
    this.setState({
      saving: true,
    });
    const { translate } = this.props;
    dataProvider(UPDATE, 'entities', { id: record.id, data: record })
      .then(() => {
        this.setState({
          saving: false,
        });
        NotificationManager.success(translate('app.messages.entitySaved'));
      })
      .catch((e) => {
        this.setState({
          saving: false,
        });
        NotificationManager.error(translate(e.message), translate('app.messages.entityNotSaved'));
      });
  }

  render() {
    return (
      <Show {...this.props}
            title={<EntityTitle/>}
            actions={<ListButton label=''/>}>
        <TabbedShowLayout>
          <Tab label='app.label.details'>
            <SimpleForm
              toolbar={<EntityEditToolbar translate={this.props.translate} />}
              saving={this.state.saving}
              save={this.saveEntity}>
              <TextField label='app.label.id' source='legalId'/>
              <ImageField source='logoUrl' title=''/>
              <TextField label='app.label.name' source='name'/>
              <TextField label='app.label.phone' source='phone'/>
              <EmailField label='app.label.email' source='email'/>
              <BooleanField label='app.label.emailBlackListed' source='emailBlackListed' />
              <BooleanInput label='app.label.visible' source='visible' />
              <TextField label='app.label.activeCoupon' source='activeCoupon'/>
              <SelectInput
                disabled={this.state.saving}
                label='app.label.sriMode'
                source='sriMode'
                optionText='name'
                optionValue='id'
                choices={[
                  { id: 'PRODUCTION', name: 'app.sriMode.production' },
                  { id: 'SANBOX', name: 'app.sriMode.sandbox' },
                ]}/>
              <DateField
                label='app.label.created'
                source='createdAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
              <TextField label='app.label.documents' source='documents'/>
              <DateField
                label='app.label.documentsExpires'
                source='documentsExpiresAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
              <TextField label='app.label.apiDocuments' source='apiDocuments' />
              <DateField
                label='app.label.apiDocumentsExpires'
                source='apiDocumentsExpiresAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
              <BooleanField label='app.label.touristRegistry' source='touristRegistry' />
              <TextField label='app.label.resellerDocuments' source='resellerDocuments' />
              <DateField
                label='app.label.resellerDocumentsExpiresAt'
                source='resellerDocumentsExpiresAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
              <BooleanField label='app.label.isReseller' source='isReseller' />
              <TextField label='app.label.resellerCoupon' source='resellerCoupon' />
              <TextField label='app.label.signId' source='signLegalId'/>
              <DateField
                label='app.label.signExpires'
                source='signExpiresAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
            </SimpleForm>
          </Tab>
          <Tab label='app.label.users'>
            <ReferenceManyField reference='users' target='entity_id' addLabel={false}>
              <Datagrid>
                <TextField label='app.label.name' source='name'/>
                <EmailField label='app.label.email' source='email'/>
                <BooleanField label='app.label.visible' source='visible' />
                <DateField
                  label='app.label.created'
                  source='createdAt'
                  options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
                <ShowButton label=''/>
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label='app.label.clients'>
            <ReferenceManyField reference='clients' target='entity_id' addLabel={false}>
              <Datagrid>
                <TextField label='app.label.id' source='legalId'/>
                <TextField label='app.label.name' source='name'/>
                <EmailField label='app.label.email' source='email'/>
                <DateField
                  label='app.label.created'
                  source='createdAt'
                  options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
                <ShowButton label=''/>
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label='app.label.plans'>
            <ReferenceManyField reference='entity-plans' target='entity_id' addLabel={false}>
              <Datagrid>
                <TextField label='app.label.name' source='name'/>
                <TextField label='app.label.amount' source='amount'/>
                <TextField label='app.label.usedAmount' source='usedAmount'/>
                <DateField
                  label='app.label.created'
                  source='createdAt'
                  options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
                <DateField
                  label='app.label.expires'
                  source='expiresAt'
                  options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
                <BooleanField label='app.label.enabled' source='enabled'/>
                <EntityPlanDisableButton/>
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    );
  }
}

export default translate(EntityDetail);
