
import React from 'react';

import {FunctionField, ReferenceField, translate} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {};

const TransactionInvoiceField = withStyles(styles)(
    ({ classes, translate, ...props }) => {
        const value = props.record['invoiceId'];
        if (!value) {
          return <span/>;
        }
        return (
          <ReferenceField {...props}>
            <FunctionField
              label="app.label.code"
              render={record => {
                return `(${record.entityName}) ${record.establishment}-${record.emissionPoint}-${record.code}`;
              }} />
          </ReferenceField>
        );
    });

TransactionInvoiceField.defaultProps = ReferenceField.defaultProps;

export default translate(TransactionInvoiceField);
