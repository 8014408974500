import React from 'react';
import {
    translate,
    TextInput,
    Filter,
    DateInput,
} from 'react-admin';

const DocumentFilter = translate((props) => (
    <Filter {...props}>
        <TextInput
            label='app.action.search'
            source='q'
            alwaysOn />
        <DateInput
            label='app.label.from'
            source='from'
            alwaysOn />
        <DateInput
            label='app.label.to'
            source='to'
            alwaysOn />
    </Filter>
));

export default DocumentFilter;
