import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

class CustomButton extends Component {
  render() {
    return <Button { ...this.props}
                   startIcon={this.props.icon}>
      {this.props.children}
    </Button>;
  }
}

CustomButton.propTypes = {
  record: PropTypes.object,
};

export default connect(null, {})(CustomButton);
