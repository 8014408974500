
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const Configuration = () => (
    <Card>
        <CardContent>
        </CardContent>
    </Card>
);

export default Configuration;
