import React from 'react';
import { NotificationManager } from 'react-notifications';
import {
  DateField,
  FunctionField,
  Show,
  TextField,
  TabbedShowLayout,
  Tab,
  EmailField,
  ReferenceManyField,
  Datagrid,
  ShowButton,
  ListButton,
  UPDATE,
  translate,
  SimpleForm,
  TextInput,
  BooleanField,
  BooleanInput,
} from 'react-admin';

import UserTitle from "./UserTitle";

import UserEditToolbar from "./UserEditToolbar";
import dataProvider from '../../provider/dataProvider';

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { saving: false, };
    this.saveUser = this.saveUser.bind(this);
  }

  saveUser(record) {
    this.setState({
      saving: true,
    });
    const { translate } = this.props;
    dataProvider(UPDATE, 'users', { id: record.id, data: record })
      .then(() => {
        this.setState({
          saving: false,
        });
        NotificationManager.success(translate('app.messages.userSaved'));
      })
      .catch((e) => {
        this.setState({
          saving: false,
        });
        NotificationManager.error(translate(e.message), translate('app.messages.userNotSaved'));
      });
  }

  render() {
    return (
      <Show {...this.props}
            title={<UserTitle/>}
            actions={<ListButton label=''/>}>
        <TabbedShowLayout>
          <Tab label='app.label.details'>
            <SimpleForm
              toolbar={<UserEditToolbar translate={this.props.translate}/>}
              saving={this.state.saving}
              save={this.saveUser}>
              <TextField label='app.label.name' source='name'/>
              <EmailField label='app.label.email' source='email'/>
              <TextInput label='app.label.password' source='password' type='password'/>
              <BooleanInput label='app.label.visible' source='visible' />
              <TextField label='app.label.activeCoupon' source='activeCoupon'/>
              <BooleanInput label='app.label.posAccess' source='posAccess' />
              <BooleanInput label='app.label.apiAccess' source='apiAccess' />
              <BooleanInput label='app.label.isReseller' source='isReseller' />
              <BooleanField label='app.label.emailBlackListed' source='emailBlackListed' />
              <DateField
                label='app.label.created'
                source='createdAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
              <TextField label='app.label.apiUser' source='apiUser' />
              <TextField label='app.label.apiPassword' source='apiPassword' />
              <TextField label='app.label.apiDocuments' source='apiDocuments' />
              <DateField
                label='app.label.apiDocumentsExpires'
                source='apiDocumentsExpiresAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
              <TextField label='app.label.resellerDocuments' source='resellerDocuments' />
              <DateField
                label='app.label.resellerDocumentsExpiresAt'
                source='resellerDocumentsExpiresAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
              <BooleanField label='app.label.privacyPolicyAccepted' source='privacyPolicy' />
              <TextField label='app.label.privacyPolicyDevice' source='privacyPolicyDevice' />
              <DateField
                label='app.label.privacyPolicyAt'
                source='privacyPolicyAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
              <FunctionField
                label="app.label.marketingCampaignAccepted"
                source='marketingCampaign'
                render={(record, source) => {
                  if (record.marketingCampaign) {
                    return <BooleanInput record={record} source={source} label={''} />;
                  }
                  return <BooleanField record={record} source={source} />;
                }} />
              <TextField label='app.label.marketingCampaignDevice' source='marketingCampaignDevice' />
              <DateField
                label='app.label.marketingCampaignAt'
                source='marketingCampaignAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
            </SimpleForm>
          </Tab>
          <Tab label='app.label.entities'>
            <ReferenceManyField reference='entities' target='user_id' addLabel={false}>
              <Datagrid>
                <TextField label='app.label.id' source='legalId'/>
                <TextField label='app.label.name' source='name'/>
                <EmailField label='app.label.email' source='email'/>
                <DateField
                  label='app.label.created'
                  source='createdAt'
                  options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
                <ShowButton label=''/>
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    );
  }
}

export default translate(UserDetail);
