import React from 'react';
import {
    translate
} from 'react-admin';

const TransactionTitle = translate(({ translate, record }) => (
    <span>{record ? `${translate('app.transaction.' + record.vendor)} "${record.id}"` : ''}</span>
));

export default TransactionTitle;
