import React from 'react';

import { TextField, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = {
  created: { color: '#165a9c !important', fontWeight: 'bold !important', },
  pendent: { color: '#ad9a10 !important', fontWeight: 'bold !important', },
  authorized: { color: '#42ad10 !important', fontWeight: 'bold !important', },
  unauthorized: { color: '#ad2310 !important', fontWeight: 'bold !important', },
  cancelled: { color: '#6c5c40 !important', fontWeight: 'bold !important', },
  sent: { color: '#8a9225 !important', fontWeight: 'bold !important', },
};

const DocumentStateField = withStyles(styles)(
  ({ classes, translate, source, ...props }) => {
    const value = props.record[ source ];
    const fieldProps = {
      record: {
        [ source ]: translate('app.state.' + value)
      },
      source: source,
    };
    return <TextField
      className={classnames({
        [ classes.created ]: value === 'created' || value === 'draft',
        [ classes.pendent ]: value === 'pendent',
        [ classes.authorized ]: value === 'authorized',
        [ classes.unauthorized ]: value === 'unauthorized',
        [ classes.cancelled ]: value === 'cancelled',
        [ classes.sent ]: value === 'sent',
      })}
      {...fieldProps} />
  });

DocumentStateField.defaultProps = TextField.defaultProps;

export default translate(DocumentStateField);
