import React from 'react';
import {
  translate,
  DateField,
  ReferenceField,
  TextField,
  Tab,
  ImageField,
  EmailField,
} from 'react-admin';

const EntityReference = ({ ...props }) => (
  <Tab label='app.label.entity' {...props}>
    <ReferenceField label='app.label.id' reference='entities' source='entityId' linkType='show'>
      <TextField label='app.label.id' source='legalId'/>
    </ReferenceField>
    <ReferenceField label='' reference='entities' source='entityId' linkType={false}>
      <ImageField source='logoUrl' title=''/>
    </ReferenceField>
    <ReferenceField label='app.label.name' reference='entities' source='entityId' linkType={false}>
      <TextField label='app.label.name' source='name'/>
    </ReferenceField>
    <ReferenceField label='app.label.email' reference='entities' source='entityId' linkType={false}>
      <EmailField label='app.label.email' source='email'/>
    </ReferenceField>
    <ReferenceField label='app.label.created' reference='entities' source='entityId' linkType={false}>
      <DateField
        label='app.label.created'
        source='createdAt'
        options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
    </ReferenceField>
  </Tab>
);

export default translate(EntityReference);
