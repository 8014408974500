import React from 'react';
import {
  FunctionField,
  ShowButton,
  List,
  translate,
  Datagrid,
  RefreshButton,
  TextField,
  DateField,
  Responsive,
  SimpleList,
} from 'react-admin';

import DocumentTypeField from './DocumentTypeField';
import DocumentStateField from './DocumentStateField';

import DocumentFilter from "./DocumentFilter";

export const DocumentsList = (props) => {
  return (
    <List {...props}
          title={'app.label.documents'}
          filters={<DocumentFilter/>}
          exporter={false}
          bulkActionButtons={false}
          actions={<RefreshButton label=''/>}>
      <Responsive
        small={
          <SimpleList {...props}
                      linkType="show"
                      primaryText={record => props.translate('app.document.' + record.type)}
                      secondaryText={record => new Date(record.createdAt).toLocaleDateString()}
                      tertiaryText={record => props.translate('app.state.' + record.state)}/>
        }
        medium={
          <Datagrid>
            <DocumentTypeField label='app.label.type' source='type'/>
            <FunctionField
              label="app.label.code"
              render={record => `${record.establishment}-${record.emissionPoint}-${record.code}`} />
            <DocumentStateField label='app.label.state' source='state'/>
            <DateField
              label='app.label.created'
              source='createdAt'
              options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
            <ShowButton label=''/>
          </Datagrid>
        }
        large={
          <Datagrid>
            <DocumentTypeField label='app.label.type' source='type'/>
            <FunctionField
              label="app.label.code"
              render={record => `${record.establishment}-${record.emissionPoint}-${record.code}`} />
            <DocumentStateField label='app.label.state' source='state'/>
            <TextField label='app.label.accessCode' source='accessCode'/>
            <DateField
              label='app.label.created'
              source='createdAt'
              options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
            <DateField
              label='app.label.authorized'
              source='authorizedAt'
              options={{
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              }}/>
            <ShowButton label=''/>
          </Datagrid>
        }/>
    </List>);
};

export default translate(DocumentsList);
