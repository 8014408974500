import React from 'react';

import { TextField, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = {
  sent: { color: '#165a9c !important', fontWeight: 'bold !important', },
  opened: { color: '#ad9a10 !important', fontWeight: 'bold !important', },
  delivered: { color: '#42ad10 !important', fontWeight: 'bold !important', },
  bounce: { color: '#ad2310 !important', fontWeight: 'bold !important', },
  complaint: { color: '#8a9225 !important', fontWeight: 'bold !important', },
};

const DocumentEmailLastEventField = withStyles(styles)(
  ({ classes, translate, source, ...props }) => {
    const value = props.record[ source ];
    const fieldProps = {
      record: {
        [ source ]: translate('app.state.' + value)
      },
      source: source,
    };
    return <TextField
      className={classnames({
        [ classes.sent ]: value === 'Send' || value === 'sent',
        [ classes.opened ]: value === 'opened',
        [ classes.delivered ]: value === 'delivered' || value === 'Delivery',
        [ classes.bounce ]: value === 'bounce' || value === 'Bounce',
        [ classes.complaint ]: value === 'Complaint',
      })}
      {...fieldProps} />
  });

DocumentEmailLastEventField.defaultProps = TextField.defaultProps;

export default translate(DocumentEmailLastEventField);
