import React from 'react';
import { translate, Title } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getRequest } from '../utils/http';
import ChartistGraph from 'react-chartist';
import './dashboard.css';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statistics: null,
    };
  }

  componentDidMount() {
    this.fetchStatistics();
  }

  fetchStatistics() {
    if (!this.state.statistics) {
      getRequest({
        endpoint: '/statistics',
        callback: (err, data) => {
          if (err) {
            localStorage.clear();
            window.location.reload();
          } else {
            this.setState({ statistics: data });
          }
        },
      });
    }
  }

  render() {
    const { translate } = this.props;
    const { statistics } = this.state;
    let todayDocuments = <CircularProgress style={{ margin: '0 auto' }} />;
    if (statistics) {
      const axisX = {
        bills: `(${statistics.billsAuthorizedToday}/${statistics.billsToday})`,
        receipts: `(${statistics.receiptsToday})`,
        estimates: `(${statistics.estimatesPaidToday}/${statistics.estimatesToday})`,
        creditNotes: `(${statistics.creditNotesAuthorizedToday}/${statistics.creditNotesToday})`,
        remissionGuides: `(${statistics.remissionsAuthorizedToday}/${statistics.remissionsToday})`,
        retentions: `(${statistics.retentionsAuthorizedToday}/${statistics.retentionsToday})`,
      };
      const data = {
        labels: Object.keys(axisX).map((key) => {
          return translate(`app.document.${key}`) + ` ${axisX[key]}`;
        }),
        series: [
          [
            statistics.billsToday,
            statistics.receiptsToday,
            statistics.estimatesToday,
            statistics.creditNotesToday,
            statistics.remissionsToday,
            statistics.retentionsToday,
          ],
          [
            statistics.billsAuthorizedToday,
            0,
            statistics.estimatesPaidToday,
            statistics.creditNotesAuthorizedToday,
            statistics.remissionsAuthorizedToday,
            statistics.retentionsAuthorizedToday,
          ],
        ]
      };
      const options = {
        high: Math.max(
          statistics.billsToday,
          statistics.receiptsToday,
          statistics.estimatesToday,
          statistics.creditNotesToday,
          statistics.remissionsToday,
          statistics.retentionsToday,
        ) + 2,
        low: 0,
        onlyInteger: true,
        stretch: true,
      };
      todayDocuments = <div>
        <h4 style={{ marginLeft: '20px' }}>{translate('app.label.todayStatistics')}</h4>
        <ChartistGraph data={data} options={options} type={'Bar'} />
      </div>;
    }

    return (
      <Card>
        <Title title='Tu Facturero' />
        <CardHeader title='Tu Facturero' />
        <CardContent>
          {todayDocuments}
        </CardContent>
      </Card>
    );
  }
}

export default translate(Dashboard);
