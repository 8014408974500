import Icon from '@material-ui/icons/People';

import UsersList from './UsersList';
import UserDetail from './UserDetail';

export default {
    name: 'users',
    list: UsersList,
    show: UserDetail,
    icon: Icon
};
