
import React from 'react';

import { TextField, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import banks from "../../model/banks";

const styles = {
  [banks.asMap.bolivariano]: { color: 'gray !important' },
  [banks.asMap.pacifico]: { color: 'gray !important' },
  [banks.asMap.pichincha]: { color: 'orange !important' },
  [banks.asMap.guayaquil]: { color: 'blue !important' },
};

const TransactionBankField = withStyles(styles)(
    ({ classes, translate, ...props }) => {
        const value = props.record[props.source];
        if (!value) {
          return <span/>;
        }
        const fieldProps = {
            record: {
                [props.source]: translate('app.bank.' + value)
            },
            source: props.source,
        };
        return <TextField
            className={classnames({
                [classes.pichincha]: value === banks.asMap.pichincha,
                [classes.guayaquil]: value === banks.asMap.guayaquil,
                [classes.pacifico]: value === banks.asMap.pacifico,
                [classes.bolivariano]: value === banks.asMap.bolivariano,
            })}
            {...fieldProps} />
    });

TransactionBankField.defaultProps = TextField.defaultProps;

export default translate(TransactionBankField);
