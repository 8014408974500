export const touwolfCode = 'touwolf';
const map = {
    eqon: 'eqon',
  [touwolfCode]: touwolfCode,
};

const companies = Object.keys(map);
companies.asMap = map;

export default companies;
