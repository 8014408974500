
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import Config from '../Config';

export const userRoleKey = 'USER_ROLE_KEY_STORE';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(Config.BASE_URL + '/login', {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {
                localStorage.setItem(Config.USER_SESSION_KEY, JSON.stringify(json));
                if (json && json.user && json.user.role) {
                    localStorage.setItem(userRoleKey, json.user.role);
                }
            });
    }

    if (type === AUTH_LOGOUT) {
        localStorage.clear();
        return Promise.resolve();
    }

    if (type === AUTH_ERROR) {
        const { status } = params;
        if (status === 401 || status === 403) {
            localStorage.clear();
            return Promise.reject();
        }
        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        return localStorage.getItem(Config.USER_SESSION_KEY)
            ? Promise.resolve()
            : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem(userRoleKey);
        return role ? Promise.resolve(role) : Promise.reject();
    }

    return Promise.reject('Unknown method');
};
