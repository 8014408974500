import React from 'react';
import {
    translate,
    DateField,
    ReferenceField,
    TextField,
    Tab,
    EmailField,
} from 'react-admin';

const UserReference = ({ ...props }) => (
    <Tab label='app.label.user' { ...props }>
        <ReferenceField label='app.label.name' reference='users' source='userId' linkType='show'>
            <TextField label='app.label.name' source='name' />
        </ReferenceField>
        <ReferenceField label='app.label.email' reference='users' source='userId' linkType={false}>
            <EmailField label='app.label.email' source='email' />
        </ReferenceField>
        <ReferenceField label='app.label.created' reference='users' source='userId' linkType={false}>
            <DateField
                label='app.label.created'
                source='createdAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} />
        </ReferenceField>
    </Tab>
);

export default translate(UserReference);
