import Config from '../Config';

const headers = (isJson) => {
  const apiKey = JSON.parse(localStorage.getItem(Config.USER_SESSION_KEY));
  const base = isJson ? {
    'Content-Type': 'application/json',
  } : {};
  return apiKey ? {
    ...base,
    'Authorization': `Bearer ${apiKey['token']}`,
  } : base;
};

const processData = (data, callback) => {
  if (data.ok) {
    data.json
      .then((json) => callback(null, json))
      .catch((e) => callback(e));
  } else {
    data.json
      .then((json) => callback(json.message || 'Error'))
      .catch((e) => callback(e));
  }
};

export const getRequest = ({ endpoint, callback }) => {
  const options = {
    method: 'GET',
    headers: headers(),
  };
  fetch(`${Config.BASE_URL}${endpoint}`, options)
    .then(response => {
      return {
        ok: response.ok,
        json: response.json(),
      };
    })
    .then(data => processData(data, callback))
    .catch((e) => callback(e));
};

export const postRequest = ({ endpoint, data, callback }) => {
  const options = {
    method: 'POST',
    body: data ? JSON.stringify(data) : null,
    headers: headers(true),
  };
  fetch(`${Config.BASE_URL}${endpoint}`, options)
    .then(response => {
      return {
        ok: response.ok,
        json: response.json(),
      };
    })
    .then(data => processData(data, callback))
    .catch((e) => callback(e));
};

export const base64toBlob = (base64, sliceSize = 512) => {
  if (!base64) {
    return null;
  }
  let dataSepIndex = base64.indexOf(';');
  if (dataSepIndex < 0) {
    return null;
  }
  let dataContentIndex = base64.indexOf(',', dataSepIndex);
  if (dataContentIndex < 0) {
    return null;
  }
  const contentType = base64.substring('data:'.length, dataSepIndex);
  const b64Data = base64.substring(dataContentIndex + 1);
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, {type: contentType});
};
