import React from 'react';
import {
  ShowButton,
  List,
  translate,
  Datagrid,
  RefreshButton,
  DateField,
  Responsive,
  SimpleList,
  TextField,
  NumberField,
  BooleanField,
} from 'react-admin';

import TransactionStateField from './TransactionStateField';
import TransactionVendorField from './TransactionVendorField';

import TransactionFilter from "./TransactionFilter";
import TransactionBankField from "./TransactionBankField";
import TransactionInvoiceField from "./TransactionInvoiceField";
import TransactionValidateButton from "./TransactionValidateButton";
import TransactionInvoiceButton from "./TransactionInvoiceButton";

export const TransactionsList = (props) => {
    return (
        <List {...props}
              title={'app.label.transactions'}
              filters={<TransactionFilter/>}
              exporter={false}
              bulkActionButtons={false}
              actions={<RefreshButton label=''/>}>
            <Responsive
                small={
                    <SimpleList {...props}
                        linkType="show"
                        primaryText={record => props.translate('app.transaction.' + record.vendor)}
                        secondaryText={record => new Date(record.registered).toLocaleDateString()}
                        tertiaryText={record => props.translate('app.state.' + record.state)} />
                }
                medium={
                    <Datagrid>
                      <TransactionVendorField label='app.label.vendor' source='vendor' />
                      <TextField label='app.label.entity' source='entityName' />
                      <NumberField label='app.label.amount' source='amount' />
                      <TransactionStateField label='app.label.state' source='state' />
                      <BooleanField label='app.label.validated' source='validated' />
                      <BooleanField label='app.label.hasFile' source='hasFile' />
                      <TransactionBankField label='app.label.bank' source='bank' />
                      <TextField label='app.label.id' source='uniqueId' />
                      <TransactionInvoiceField label='app.document.bill' reference='documents' source='invoiceId' linkType="show"/>
                      <DateField
                        label='app.label.registered'
                        source='registered'
                        options={{weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'}}/>
                      <TransactionValidateButton />
                      <TransactionInvoiceButton />
                      <ShowButton label=''/>
                    </Datagrid>
                } />
        </List>);
};

export default translate(TransactionsList);
