import React from 'react';
import {SaveButton, Toolbar,} from 'react-admin';
import compose from 'recompose/compose';
import Dropzone from 'react-dropzone';
import {NotificationManager} from 'react-notifications';
import AddDocumentsIcon from '@material-ui/icons/AccountBalanceWallet';
import SendDocumentsIcon from '@material-ui/icons/Update';
import CouponIcon from '@material-ui/icons/Beenhere';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button';
import CustomButton from '../widget/CustomButton';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {getRequest, postRequest} from '../../utils/http';
import MenuItem from '@material-ui/core/MenuItem';
import {createStyles, withStyles} from "@material-ui/core/styles";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import BlackListIcon from '@material-ui/icons/SpeakerNotesOff';

const payments = [
  {
    value: 'transfer',
    label: 'app.transaction.transfer',
  },
  {
    value: 'cash',
    label: 'app.transaction.cash',
  },
  {
    value: 'credit_card',
    label: 'app.transaction.credit_card',
  },
  {
    value: 'debit_card',
    label: 'app.transaction.debit_card',
  },
];

const styles = createStyles({
  dropZone: {
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    textAlign: 'center',
    color: '#999',
    marginTop: '20px',
  },
});

class EntityEditToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addingDocuments: false,
      sendingDocuments: false,
      processingDocuments: false,
      dialogCouponActive: false,
      settingCoupon: false,
      couponValue: '',
      plans: [],
      resellers: [],
      resellersFetched: false,
      generateBill: true,
      forApi: false,
      forReseller: false,
      customNote: '',
      customPrice: 0,
      customDocuments: 100,
      customDays: 365,
      payment: 'transfer',
      coupon: null,
      from: new Date(),
      proofOfPayment: null,
      resellerDocuments: 0,
      resellerId: null,
      selectedReseller: null,
    };
  }

  fetchPlans() {
    const {plans} = this.state;
    if (!plans || !plans.length) {
      getRequest({
        endpoint: '/plans',
        callback: (err, data) => {
          if (err) {
            console.error(err.toString());
          } else {
            this.setState({plans: data.list});
          }
        },
      });
    }
  }

  fetchResellers() {
    const {resellersFetched,} = this.state;
    if (!resellersFetched) {
      getRequest({
        endpoint: '/resellers',
        callback: (err, data) => {
          if (err) {
            console.error(err.toString());
          } else {
            this.setState({resellers: data.list, resellersFetched: true});
          }
        },
      });
    }
  }

  openDocumentsDialog = () => {
    this.fetchPlans();
    this.fetchResellers();
    this.setState({addingDocuments: true});
  };

  closeDocumentsDialog = () => {
    this.setState({addingDocuments: false});
  };

  openSendDocumentsDialog = () => {
    this.setState({sendingDocuments: true});
  };

  openResellerCouponDialog = () => {
    this.setState({dialogCouponActive: true});
  };

  closeSendDocumentsDialog = () => {
    this.setState({sendingDocuments: false});
  };

  closeResellerCouponDialog = () => {
    this.setState({dialogCouponActive: false});
  };

  requestData = (planId) => {
    const {
      customPrice,
      customDocuments,
      customDays,
      generateBill,
      forApi,
      forReseller,
      customNote,
      payment,
      coupon,
      proofOfPayment,
      resellerDocuments,
      resellerId,
    } = this.state;
    return new Promise((resolve, reject) => {
      let data = {
        planId,
        price: customPrice,
        amount: customDocuments,
        days: customDays,
        generateBill,
        forApi,
        forReseller,
        customNote,
        payment,
        coupon,
        resellerDocuments,
        resellerId,
      };
      if (!!proofOfPayment) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(proofOfPayment);
        fileReader.onload = () => {
          data.file = fileReader.result;
          resolve(data);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        resolve(data);
      }
    });
  };

  addPlan = (planId) => {
    const {record, translate} = this.props;
    this.setState({processingDocuments: true});
    this.requestData(planId).then((data) => {
      postRequest({
        endpoint: `/entities/${record.id}/add-documents`,
        data,
        callback: (err, data) => {
          if (err) {
            NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
          } else {
            NotificationManager.success(data);
            setTimeout(() => {
              window.location.reload();
            }, 200);
          }
          this.setState({processingDocuments: false, addingDocuments: err});
        },
      });
    }).catch((err) => {
      NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
      this.setState({processingDocuments: false, addingDocuments: err});
    });
  };

  resendDocuments = () => {
    const {record, translate} = this.props;
    const {from} = this.state;
    this.setState({processingDocuments: true});
    postRequest({
      endpoint: `/entities/${record.id}/resend-documents?from=${from.getTime()}`,
      data: {},
      callback: (err, data) => {
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          NotificationManager.success(data);
        }
        this.setState({processingDocuments: false, sendingDocuments: err});
      },
    });
  };

  setCoupon = () => {
    const {record, translate} = this.props;
    const {couponValue} = this.state;
    this.setState({settingCoupon: true});
    postRequest({
      endpoint: `/entities/${record.id}/set-reseller-coupon`,
      data: {
        coupon: couponValue,
      },
      callback: (err, data) => {
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          NotificationManager.success(data);
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
        this.setState({settingCoupon: false, dialogCouponActive: false});
      },
    });
  };

  addCustomPlan = () => {
    const {translate} = this.props;
    const {customPrice, customDocuments, customDays} = this.state;
    if (!customPrice || isNaN(customPrice) || customPrice <= 0) {
      NotificationManager.error(translate('app.messages.invalidPrice'), '');
      return;
    }
    if (!customDocuments || isNaN(customDocuments) || customDocuments <= 0) {
      NotificationManager.error(translate('app.messages.invalidDocuments'), '');
      return;
    }
    if (!customDays || isNaN(customDays) || customDays <= 0) {
      NotificationManager.error(translate('app.messages.invalidDays'), '');
      return;
    }
    this.addPlan();
  };

  addFromReseller = () => {
    const {translate} = this.props;
    const {resellerDocuments, resellerId} = this.state;
    if (!resellerDocuments || isNaN(resellerDocuments) || resellerDocuments <= 0) {
      NotificationManager.error(translate('app.messages.invalidDocuments'), '');
      return;
    }
    if (!resellerId || isNaN(resellerId) || resellerId <= 0) {
      NotificationManager.error(translate('app.messages.invalidResellerId'), '');
      return;
    }
    this.addPlan();
  };

  toggleBlacklist = () => {
    this.setState({ saving: true });
    const {record, translate} = this.props;
    postRequest({
      endpoint: `/entities/${record.id}/un-blacklist`,
      data: {},
      callback: (err) => {
        this.setState({ saving: false });
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          NotificationManager.success(translate('app.messages.emailRemovedFromBlacklist') + ' ' + record.id);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      },
    });
  };

  render() {
    const {record, translate, classes = {},} = this.props;
    console.log('record', record);
    const {
      addingDocuments, sendingDocuments, processingDocuments,
      plans, resellers, resellersFetched, generateBill,
      forApi, forReseller, customPrice, coupon, payment,
      customDocuments, customDays, customNote, from, proofOfPayment,
      dialogCouponActive, settingCoupon,
      resellerDocuments, resellerId, selectedReseller,
    } = this.state;
    const hasPlans = !!plans && !!plans.length;
    // const isReseller = !!record && !!record.isReseller;
    const toolbar = (
      <Toolbar {...this.props} >
        <SaveButton/>
        <CustomButton
          className="plan-user-button"
          icon={<AddDocumentsIcon/>}
          onClick={this.openDocumentsDialog}
        >
          {translate('app.action.addDocuments')}
        </CustomButton>
        <CustomButton
          className="send-user-button"
          icon={<SendDocumentsIcon/>}
          onClick={this.openSendDocumentsDialog}
        >
          {translate('app.action.resendNonAuthDocuments')}
        </CustomButton>
        <CustomButton
          className="set-coupon-reseller-button"
          icon={<CouponIcon/>}
          onClick={this.openResellerCouponDialog}
        >
          {translate('app.action.setCouponReseller')}
        </CustomButton>
        {record.emailBlackListed && <CustomButton
          className="plan-user-button"
          icon={<BlackListIcon/>}
          onClick={this.toggleBlacklist}
        >
          {translate('app.action.toggleBlacklist')}
        </CustomButton>}
      </Toolbar>
    );

    const addDocumentsDialog = (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='lg'
        open={addingDocuments}
        onClose={this.closeDocumentsDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {translate('app.action.addDocuments')}
        </DialogTitle>
        {processingDocuments && <CircularProgress style={{margin: '0 auto'}}/>}
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                disabled={processingDocuments}
                checked={generateBill}
                onChange={(event) => {
                  this.setState({generateBill: event.target.checked});
                }}
                name="generate-bill"
                color="primary"
              />
            }
            label={translate('app.action.generateBill')}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={processingDocuments}
                checked={forApi}
                onChange={(event) => {
                  this.setState({forApi: event.target.checked, forReseller: false});
                }}
                name="for-api"
                color="primary"
              />
            }
            label={translate('app.action.forApi')}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={processingDocuments}
                checked={forReseller}
                onChange={(event) => {
                  this.setState({forReseller: event.target.checked, forApi: false});
                }}
                name="for-reseller"
                color="primary"
              />
            }
            label={translate('app.action.forReseller')}
          />
          <TextField
            disabled={processingDocuments}
            variant="outlined"
            id="entity-coupon"
            label={translate('app.label.coupon')}
            type="text"
            value={coupon}
            onChange={(event) => {
              this.setState({coupon: event.target.value});
            }}
          />
          <TextField
            id="entity-payment"
            select
            disabled={processingDocuments}
            label={translate('app.label.payment')}
            value={payment}
            onChange={(event) => {
              this.setState({payment: event.target.value});
            }}
            style={{marginLeft: '10px', width: 200}}
            variant="outlined"
          >
            {payments.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {translate(option.label)}
              </MenuItem>
            ))}
          </TextField>
          <br/>
          <span>
            {(!proofOfPayment && !processingDocuments) && <Dropzone
              onDrop={(files) => {
                if (!processingDocuments && files && files.length) {
                  this.setState({proofOfPayment: files[0],});
                }
              }}
              accept="application/pdf,image/*"
              multiple={false}
              className={classes.dropZone}>
              {translate('app.label.proofOfPayment')}
            </Dropzone>}
            {!!proofOfPayment && <span><IconButton
              disabled={processingDocuments}
              size="small"
              onClick={() => {
                this.setState({proofOfPayment: null,});
              }}
              title={translate('ra.action.delete')}
            >
              <RemoveCircle color={processingDocuments ? 'action' : 'error'}/>
            </IconButton>&nbsp;<small>
              {translate('app.label.proofOfPayment')}: <b>{proofOfPayment.name}</b>
            </small></span>}
          </span>
          <TextareaAutosize
            rowsMin={2} rowsMax={4}
            disabled={processingDocuments}
            variant="outlined"
            id="entity-custom-note"
            placeholder={translate('app.label.notes')}
            type="text"
            value={customNote}
            onChange={(event) => {
              this.setState({customNote: event.target.value});
            }}
            style={{marginTop: '20px', width: '99%', display: 'block',}}
          />

          {hasPlans && <h4>{translate('app.messages.availablePlans')}</h4>}
          <DialogContentText>
            {!hasPlans && <CircularProgress size={20} style={{marginTop: '10px'}}/>}
            {hasPlans && <List bulkActionButtons={false}>
              <Divider/>
              {plans.map((plan) => {
                let details = `$${plan.price.toFixed(2)}, ${plan.amount} ${translate('app.label.documents')}`;
                details += `, ${plan.days} ${translate('app.label.days')}`;
                return (
                  <div>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar src={plan.imageUrl} alt={plan.code}/>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<React.Fragment>
                          <Typography component="span" style={{display: 'inline'}} color="textPrimary">
                            {plan.name}:&nbsp;
                          </Typography>
                          {details}
                        </React.Fragment>}
                        secondary={plan.description}/>
                      <Button
                        size="small" style={{marginLeft: '10px'}}
                        disabled={processingDocuments}
                        onClick={() => this.addPlan(plan.id)}
                        variant="contained" color="primary">
                        {translate('app.action.addThis')}
                      </Button>
                    </ListItem>
                    <Divider/>
                  </div>
                );
              })}
            </List>}
          </DialogContentText>
          <h4>{translate('app.messages.customPlan')}</h4>
          <TextField
            disabled={processingDocuments}
            variant="outlined"
            id="custom-plan-price"
            label={translate('app.label.price')}
            type="number"
            value={customPrice}
            onChange={(event) => {
              this.setState({customPrice: parseFloat(event.target.value)});
            }}
          />
          <TextField
            disabled={processingDocuments}
            variant="outlined"
            style={{marginLeft: '10px', marginRight: '10px'}}
            id="custom-plan-documents"
            label={translate('app.label.documents')}
            type="number"
            value={customDocuments}
            onChange={(event) => {
              this.setState({customDocuments: parseInt(event.target.value)});
            }}
          />
          <TextField
            disabled={processingDocuments}
            variant="outlined"
            id="custom-plan-days"
            label={translate('app.label.days')}
            type="number"
            value={customDays}
            onChange={(event) => {
              this.setState({customDays: parseInt(event.target.value)});
            }}
          />
          <Button
            disabled={processingDocuments}
            size="small" style={{marginTop: '8px', marginLeft: '10px'}}
            onClick={this.addCustomPlan}
            variant="contained" color="primary">
            {translate('app.action.addCustom')}
          </Button>
          <h4>{translate('app.messages.fromReseller')}</h4>
          {!resellersFetched && <CircularProgress size={20} style={{marginRight: '10px'}}/>}
          <TextField
            disabled={processingDocuments || !resellersFetched || !resellers || !resellers.length}
            variant="outlined"
            id="reseller-documents"
            label={translate('app.label.documents')}
            type="number"
            value={resellerDocuments}
            style={{clear: 'left'}}
            onChange={(event) => {
              this.setState({resellerDocuments: parseInt(event.target.value)});
            }}
          />
          {resellersFetched && <Autocomplete
            id="reseller-id"
            disabled={processingDocuments}
            options={resellers}
            value={selectedReseller}
            onChange={(_, newValue) => {
              this.setState({
                resellerId: !!newValue ? newValue.id : null,
                selectedReseller: newValue,
              });
            }}
            getOptionLabel={(option) => {
              const name = !!option.name ? option.name.substring(0, Math.min(20, option.name.length)) : '-';
              const email = !!option.email ? option.email.substring(0, Math.min(30, option.email.length)) : '-';
              return `${name} - ${email} (${option.resellerDocuments})`;
            }}
            style={{marginRight: '10px', float: 'left', width: 500}}
            renderInput={(params) => <TextField {...params} label={translate('app.label.isReseller')} variant="outlined" />}
          />}
          <Button
            disabled={processingDocuments || !resellerId}
            size="small" style={{marginTop: '8px', marginLeft: '10px'}}
            onClick={this.addFromReseller}
            variant="contained" color="primary">
            {translate('app.action.add')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.closeDocumentsDialog}
            disabled={processingDocuments}
            color="secondary">
            {translate('app.action.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );

    const sendDocumentsDialog = (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='lg'
        open={sendingDocuments}
        onClose={this.closeSendDocumentsDialog}
        aria-labelledby="form-send-dialog-title"
      >
        <DialogTitle id="form-send-dialog-title">
          {translate('app.action.resendNonAuthDocuments')}
        </DialogTitle>
        {processingDocuments && <CircularProgress style={{margin: '0 auto'}}/>}
        <DialogContent>
          <TextField
            id="date-from"
            label={translate('app.label.from')}
            type="date"
            defaultValue={`${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`}
            onChange={(event) => {
              this.setState({from: new Date(event.target.value)});
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            disabled={processingDocuments}
            size="small" style={{marginTop: '8px', marginLeft: '10px'}}
            onClick={this.resendDocuments}
            variant="contained" color="primary">
            {translate('app.action.send')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.closeSendDocumentsDialog}
            disabled={processingDocuments}
            color="secondary">
            {translate('app.action.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );

    const setResellerCouponDialog = (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='lg'
        open={dialogCouponActive}
        onClose={this.closeSendDocumentsDialog}
        aria-labelledby="form-coupon-dialog-title"
      >
        <DialogTitle id="form-coupon-dialog-title">
          {translate('app.action.setCouponReseller')}
        </DialogTitle>
        {settingCoupon && <CircularProgress style={{margin: '0 auto'}}/>}
        <DialogContent>
          <p>{translate('app.messages.resellerCouponDescription')}</p>
          <TextField
            id="coupon-value"
            label={translate('app.label.coupon')}
            type="text"
            placeholder={translate('app.label.coupon')}
            onChange={(event) => {
              this.setState({couponValue: event.target.value});
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            disabled={settingCoupon}
            size="small" style={{marginTop: '8px', marginLeft: '10px'}}
            onClick={this.setCoupon}
            variant="contained" color="primary">
            {translate('app.action.ok')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.closeResellerCouponDialog}
            disabled={settingCoupon}
            color="secondary">
            {translate('app.action.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );

    return (
      <div>
        {toolbar}
        {addDocumentsDialog}
        {sendDocumentsDialog}
        {setResellerCouponDialog}
      </div>
    );
  }
}

export default compose(
  withStyles(styles)
)(EntityEditToolbar);
