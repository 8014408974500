import React from 'react';
import {
    translate
} from 'react-admin';

const EntityPlanTitle = translate(({ translate, record }) => (
    <span>{record ? `${translate('app.label.entityPlan')} "${record.name}" - ${record.description}` : ''}</span>
));

export default EntityPlanTitle;
