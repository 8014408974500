import React from 'react';
import {
    translate,
    TextInput,
    Filter
} from 'react-admin';

const EntityFilter = translate((props) => (
    <Filter {...props}>
        <TextInput label='app.action.search' source='q' alwaysOn />
    </Filter>
));

export default EntityFilter;
