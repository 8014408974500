import React from 'react';

import {translate} from 'react-admin';

import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CheckCircle from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import {NotificationManager} from "react-notifications";
import {postRequest} from "../../utils/http";
import banks from "../../model/banks";
import CustomButton from "../widget/CustomButton";
import {userRoleKey} from "../../provider/authProvider";

class TransactionValidateButton extends React.Component {
  constructor(props) {
    super(props);
    const { record } = props;
    this.state = {
      capturing: false,
      validating: false,
      bank: (!!record && !!record.bank) ? record.bank : banks[0],
      transactionID: (!!record && !!record.uniqueId) ? record.uniqueId : null,
      retentionCode: (!!record && !!record.retentionCode) ? record.retentionCode : null,
    };
  }

  capture = () => {
    const { capturing } = this.state;
    if (capturing) {
      return;
    }
    this.setState({
      capturing: true,
    });
  };

  validate = () => {
    const { validating, bank, transactionID, retentionCode } = this.state;
    if (validating) {
      return;
    }
    const { record, translate } = this.props;
    this.setState({
      validating: true,
    });
    postRequest({
      endpoint: `/transactions/${record.id}/validate`,
      data: {
        bank,
        uniqueId: transactionID,
        retentionCode,
      },
      callback: (err) => {
        this.setState({
          validating: false,
        });
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          this.setState({
            capturing: false,
          });
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
      },
    });
  };

  render() {
    const { translate, large } = this.props;
    const { capturing, validating, bank, transactionID, retentionCode } = this.state;
    if ('ADMINISTRATOR_VALIDATOR' !== localStorage.getItem(userRoleKey)) {
      return <div/>;
    }
    let button = large ? <CustomButton
      className="web-validate-button"
      icon={<CheckCircle />}
      onClick={this.capture}
    >
      {translate('app.action.validate')}
    </CustomButton> : <IconButton
      {...this.props}
      size="small"
      onClick={this.capture}
    >
      <CheckCircle color='primary' />
    </IconButton>;
    return <div>
      {button}
      <Dialog
        open={capturing || validating}
        onClose={false}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{translate('app.label.validateTransaction')}</DialogTitle>
        <DialogContent>
          <InputLabel id="transactionBank-label">
            {translate('app.label.bank')}
          </InputLabel>
          <Select
            disabled={validating}
            margin="dense"
            labelId="transactionBank-label"
            id="transactionBank"
            value={bank}
            fullWidth
            onChange={(e) => {
              this.setState({
                bank: e.target.value,
              });
            }}
          >
            {banks.map((value) => <MenuItem value={value}>{translate(`app.bank.${value}`)}</MenuItem>)}
          </Select>
          <TextField
            disabled={validating}
            margin="dense"
            id="transactionId"
            value={transactionID}
            label={translate('app.label.transactionId')}
            onChange={(e) => {
              this.setState({
                transactionID: e.target.value,
              });
            }}
            fullWidth
          />
          <TextField
            disabled={validating}
            margin="dense"
            id="retentionCode"
            value={retentionCode}
            label={translate('app.label.retentionNumber')}
            onChange={(e) => {
              this.setState({
                retentionCode: e.target.value,
              });
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={validating}
            onClick={() => {
              this.setState({
                capturing: false,
                validating: false,
              });
            }}
            color="primary">
            {translate('app.action.cancel')}
          </Button>
          <Button
            disabled={validating}
            onClick={this.validate}
            color="primary">
            {translate('app.action.validate')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>;
  }
}

export default translate(TransactionValidateButton);
