import Icon from '@material-ui/icons/AttachMoney';

import TransactionsList from "./TransactionsList";
import TransactionDetail from "./TransactionDetail";

export default {
    name: 'transactions',
    list: TransactionsList,
    show: TransactionDetail,
    icon: Icon
};
