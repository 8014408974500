import React from 'react';

import { TextField, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = {
  receipt: { color: '#457075 !important', fontWeight: 'bold !important', },
  bill: { color: '#0a2b4b !important', fontWeight: 'bold !important', },
  preBill: { color: '#487ba4 !important', fontWeight: 'bold !important', },
  creditNote: { color: '#047358 !important', fontWeight: 'bold !important', },
  retention: { color: '#735a04 !important', fontWeight: 'bold !important', },
  remissionGuide: { color: '#44215b !important', fontWeight: 'bold !important', },
};

const DocumentTypeField = withStyles(styles)(
  ({ classes, translate, ...props }) => {
    const value = props.record[ props.source ];
    const fieldProps = {
      record: {
        [ props.source ]: translate('app.document.' + value)
      },
      source: props.source,
    };
    return <TextField
      className={classnames({
        [ classes.receipt ]: value === 'receipt',
        [ classes.bill ]: value === 'bill',
        [ classes.preBill ]: value === 'preBill',
        [ classes.creditNote ]: value === 'creditNote',
        [ classes.retention ]: value === 'retention',
        [ classes.remissionGuide ]: value === 'remissionGuide',
      })}
      {...fieldProps} />
  });

DocumentTypeField.defaultProps = TextField.defaultProps;

export default translate(DocumentTypeField);
