import spanishMessages from 'aor-language-spanish';
import banks from "../model/banks";
import companies from '../model/companies';

const messages = {
  label: {
    phone: 'Teléfono',
    activeCoupon: 'Cupón activo',
    emailBlackListed: 'Correo en lista negra',
    visible: 'Visible',
    entities: 'Entidades',
    enabled: 'Habilitado',
    users: 'Usuarios',
    documents: 'Documentos',
    configuration: 'Configuración',
    type: 'Tipo',
    code: 'Código',
    state: 'Estado',
    accessCode: 'Código Acceso',
    registered: 'Registrado en',
    created: 'Creado',
    expires: 'Expira',
    authorized: 'Autorizado',
    usedAmount: 'Usado',
    details: 'Detalles',
    id: 'ID',
    plans: 'Planes',
    name: 'Nombre',
    email: 'Correo',
    entity: 'Entidad',
    user: 'Usuario',
    document: 'Documento',
    company: 'Compañía',
    tasks: 'Tareas',
    executed: 'Procesado',
    executesAt: 'Se procesa',
    task: 'Tarea',
    clients: 'Clientes',
    client: 'Cliente',
    from: 'Desde',
    to: 'Hasta',
    transactions: 'Transacciones',
    transaction: 'Transacción',
    vendor: 'Proveedor',
    amount: 'Cantidad',
    error: 'Error',
    password: 'Contraseña',
    days: 'Días',
    price: 'Precio',
    coupon: 'Cupón',
    payment: 'Pago',
    documentsExpires: 'Documentos expiran',
    signExpires: 'Firma expira',
    todayStatistics: 'Estadísticas de hoy',
    authorizedAt: 'Fecha autorizado',
    notified: 'Notificado',
    posAccess: 'Acceso POS',
    apiAccess: 'Acceso API',
    apiUser: 'Usuario API',
    apiPassword: 'Password API',
    notes: 'Notas',
    rejectedReason: 'Motivo de rechazo',
    emails: 'Correos',
    emailTo: 'Para',
    lastEvent: 'Último evento',
    blacklisted: 'En lista negra',
    blacklistCategory: 'Categoría lista negra',
    blacklistVendorType: 'Tipo lista negra',
    blacklistVendorSubtype: 'Subtipo lista negra',
    apiDocuments: 'Documentos API',
    apiDocumentsExpires: 'Documentos API expiran',
    sriMode: 'MODO SRI',
    signId: 'Identificación de firma electrónica',
    validated: 'Validado',
    bank: 'Banco',
    validateTransaction: 'Validar transacción',
    transactionId: 'ID de transacción',
    proofOfPayment: 'Comprobante de pago',
    proofOfPaymentDescription: 'Suba el comprobante de pago',
    hasFile: 'Archivo',
    invoiceCode: 'Código Factura',
    isReseller: 'Revendedor',
    touristRegistry: 'Registro turistico',
    resellerDocuments: 'Documentos revendedor',
    resellerDocumentsExpiresAt: 'Documentos revendedor expiran',
    resellerCoupon: 'Cupón revendedor',
    retentionCode: 'Código retención',
    retentionNumber: 'Secuencial de retención (XXX-YYY-ZZZZZZZZZ)',
    privacyPolicyAccepted: 'Política de Privacidad aceptada',
    privacyPolicyDevice: 'Dispositivo de Política de Privacidad',
    privacyPolicyAt: 'Fecha de Política de Privacidad',
    marketingCampaignAccepted: 'Campañas de marketing aceptada',
    marketingCampaignDevice: 'Dispositivo de Campañas de marketing',
    marketingCampaignAt: 'Fecha de Campañas de marketing',
  },
  document: {
    receipt: 'Recibo',
    receipts: 'Recibos',
    bill: 'Factura',
    bills: 'Facturas',
    preBill: 'Proforma',
    estimates: 'Proformas',
    creditNote: 'Nota Crédito',
    creditNotes: 'Notas de Crédito',
    retention: 'Retención',
    retentions: 'Retenciones',
    remissionGuide: 'Guía de Remisión',
    remissionGuides: 'Guías de Remisión',
    purchaseClearance: 'Liq. de Compras',
  },
  state: {
    approved: 'Aprobado',
    draft: 'Borrador',
    created: 'Creado',
    sent: 'Enviado',
    pendent: 'Pendiente',
    unsent: 'No Enviado',
    inProcess: 'En Proceso',
    authorized: 'Autorizado',
    rejected: 'Rechazado',
    cancelled: 'Cancelado',
    unauthorized: 'No Autorizado',
    Send: 'Enviado',
    opened: 'Abierto',
    delivered: 'Entregado',
    Delivery: 'Entregado',
    bounce: 'Rebotado',
    Bounce: 'Rebotado',
    complaint: 'Rechazado',
    refunded: 'Devuelta',
    unknown: 'Desconocido',
  },
  task: {
    documentNotify: 'Notificar Documento',
    documentSend: 'Enviar Documento',
    documentUpdate: 'Actualizar Estado Documento',
  },
  transaction: {
    datafast: 'Datafast',
    paymentez: 'Paymentez',
    transfer: 'Transferencia',
    cash: 'Efectivo',
    credit_card: 'Tarjeta crédito',
    debit_card: 'Tarjeta débito',
    reseller: 'Revendedor',
    touwolf: 'Touwolf',
    stripe: 'Stripe',
    unknown: 'Desconocido',
  },
  bank: {
    [banks.asMap.pichincha]: 'Pichincha',
    [banks.asMap.guayaquil]: 'Guayaquil',
    [banks.asMap.pacifico]: 'Pacífico',
    [banks.asMap.bolivariano]: 'Bolivariano',
  },
  company: {
    [companies.asMap.eqon]: 'eQon',
    [companies.asMap.touwolf]: 'Touwolf',
  },
  action: {
    loginWeb: 'Entrar en la web de usuario',
    toggleBlacklist: 'Habilitar/Deshabilitar lista negra',
    search: 'Buscar',
    save: 'Guardar',
    logInWeb: 'WEB de usuario',
    add: 'Agregar',
    addDocuments: 'Agregar documentos',
    addApiDocuments: 'Agregar documentos API',
    ok: 'Aceptar',
    cancel: 'Cancelar',
    addThis: 'Agregar este',
    generateBill: 'Generar factura',
    posAccess: 'Acceso POS',
    apiAccess: 'Acceso API',
    addCustom: 'Agregar personalizado',
    send: 'Enviar',
    resendNonAuthDocuments: 'Reenviar docs no autorizados',
    refund: 'Devolver',
    validate: 'Validar',
    seeFile: 'Ver archivo',
    setCouponReseller: 'Poner cupón revendedor',
    setInvoice: 'Poner factura',
    associateInvoice: 'Asociar factura',
    createInvoice: 'Crear factura',
    forReseller: 'Para revendedor',
    forApi: 'Para API',
  },
  messages: {
    userCodeIsRequired: 'Ingrese el código de usuario.',
    entitySelectIsRequired: 'Seleccione la entidad.',
    emailRemovedFromBlacklist: 'El email se eliminó de lista negra',
    resellerCouponDescription: 'El dueño de la entidad tendrá perfil de revendedor con un cupón para compartir.',
    documentSaved: '¡Documento guardado!',
    documentNotSaved: '¡El documento no se pudo guardar!',
    taskWasReset: '¡Tarea guardada!',
    taskNotReset: '¡La tarea no se pudo guardar!',
    userSaved: '¡Usuario guardado!',
    userNotSaved: '¡El usuario no se pudo guardar!',
    invalidPassword: '¡La contraseña no es válida!',
    errorHappened: 'Ocurrió un error',
    availablePlans: 'Planes disponibles',
    customPlan: 'Plan personalizado',
    invalidDocuments: 'Documentos no válidos',
    invalidPrice: 'Precio no válido',
    invalidDays: 'Días no válidos',
    entitySaved: '¡Entidad guardada!',
    entityNotSaved: '¡La entidad no se pudo guardar!',
    invalidTransactionState: 'Estado de transacción no válido',
    noFile: 'No hay archivo.',
    mustSearchAnAuthorizedDocument: 'Debe buscar un documento autorizado.',
    fromReseller: 'De revendedor',
    invalidResellerId: 'Revendedor no válido',
  },
  sriMode: {
    production: 'Producción',
    sandbox: 'Pruebas',
  },
};

export default { ...spanishMessages, app: messages };
