
import React from 'react';

import { TextField, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = {
    created: { color: 'gray !important' },
    refunded: { color: 'orange !important' },
    approved: { color: 'green !important' },
    rejected: { color: 'red !important' },
    cancelled: { color: 'red !important' },
};

const TransactionStateField = withStyles(styles)(
    ({ classes, translate, source, ...props }) => {
        const value = props.record[source];
        const fieldProps = {
            record: {
                [source]: translate('app.state.' + value)
            },
            source: source,
        };
        return <TextField
            className={classnames({
                [classes.created]: value === 'created',
                [classes.refunded]: value === 'refunded',
                [classes.approved]: value === 'approved',
                [classes.rejected]: value === 'rejected',
                [classes.cancelled]: value === 'cancelled',
            })}
            {...fieldProps} />
    });

TransactionStateField.defaultProps = TextField.defaultProps;

export default translate(TransactionStateField);
