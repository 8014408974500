import React from 'react';
import {
    translate
} from 'react-admin';

const EntityTitle = translate(({ translate, record }) => (
    <span>{record ? `${translate('app.label.entity')} "${record.name}" - ${record.email}` : ''}</span>
));

export default EntityTitle;
