import React from 'react';
import { NotificationManager } from 'react-notifications';
import {
  BooleanInput,
  BooleanField,
  Datagrid,
  DateField, FunctionField,
  ListButton,
  ReferenceManyField,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  translate,
  UPDATE,
} from 'react-admin';

import DocumentTypeField from './DocumentTypeField';
import dataProvider from '../../provider/dataProvider';

import DocumentTitle from "./DocumentTitle";
import DocumentEditToolbar from "./DocumentEditToolbar";
import EntityReference from "../entities/EntityReference";
import ClientReference from "../clients/ClientReference";
import UserReference from "../users/UserReference";
import DocumentEmailLastEventField from '../documents-emails/DocumentEmailLastEventField';
import CheckIcon from "@material-ui/icons/Check";
import CustomButton from "../widget/CustomButton";
import {postRequest} from "../../utils/http";

class DocumentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { saving: false, };
    this.saveDocument = this.saveDocument.bind(this);
  }

  saveDocument(record) {
    this.setState({
      saving: true,
    });
    const { translate } = this.props;
    dataProvider(UPDATE, 'documents', { id: record.id, data: record })
      .then(() => {
        this.setState({
          saving: false,
        });
        NotificationManager.success(translate('app.messages.documentSaved'));
      })
      .catch((e) => {
        this.setState({
          saving: false,
        });
        NotificationManager.error(translate(e.message), translate('app.messages.documentNotSaved'));
      });
  }

  toggleBlacklist = (record) => {
    this.setState({ saving: true });
    const { translate } = this.props;
    postRequest({
      endpoint: `/documents-emails/${record.id}/un-blacklist`,
      data: {},
      callback: (err) => {
        this.setState({ saving: false });
        if (err) {
          NotificationManager.error(err.toString(), translate('app.messages.errorHappened'));
        } else {
          NotificationManager.success(translate('app.messages.documentSaved') + ' ' + record.id);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      },
    });
  };

  render() {
    return (
      <Show {...this.props}
            title={<DocumentTitle/>}
            actions={<ListButton label=''/>}>
        <TabbedShowLayout>
          <Tab label='app.label.details'>
            <SimpleForm
              toolbar={<DocumentEditToolbar/>}
              saving={this.state.saving}
              save={this.saveDocument}>
              <DocumentTypeField label='app.label.type' source='type'/>
              <FunctionField
                label="app.label.code"
                render={record => `${record.establishment}-${record.emissionPoint}-${record.code}`} />
              <SelectInput
                disabled={this.state.saving}
                label='app.label.state'
                source='state'
                optionText='name'
                optionValue='id'
                choices={[
                  { id: 'created', name: 'app.state.created' },
                  { id: 'pendent', name: 'app.state.pendent' },
                  { id: 'sent', name: 'app.state.sent' },
                  { id: 'authorized', name: 'app.state.authorized' },
                  { id: 'unauthorized', name: 'app.state.unauthorized' },
                  { id: 'cancelled', name: 'app.state.cancelled' },
                ]}/>
              <TextField label='app.label.accessCode' source='accessCode'/>
              <DateField
                label='app.label.created'
                source='createdAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
              <BooleanInput label='app.label.notified' source='notified' />
              <DateField
                label='app.label.authorizedAt'
                source='authorizedAt'
                options={{
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                }}/>
              <TextField label='app.label.rejectedReason' source='rejectedReason'/>
            </SimpleForm>
          </Tab>
          <EntityReference {...this.props} />
          <ClientReference {...this.props} />
          <UserReference {...this.props} />
          <Tab label='app.label.emails'>
            <ReferenceManyField reference='documents-emails' target='document_id' addLabel={false}>
              <Datagrid>
                <TextField label='app.label.emailTo' source='to'/>
                <DocumentEmailLastEventField label='app.label.lastEvent' source='lastEvent'/>
                <BooleanField label='app.label.blacklisted' source='blacklisted'/>
                <TextField label='app.label.blacklistCategory' source='blacklistCategory'/>
                <TextField label='app.label.blacklistVendorType' source='blacklistVendorType'/>
                <TextField label='app.label.blacklistVendorSubtype' source='blacklistVendorSubtype'/>
                <DateField
                  label='app.label.created'
                  source='createdAt'
                  options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
                <FunctionField
                  label='app.action.toggleBlacklist'
                  render={record => {
                    if (record.blacklisted) {
                      return (<CustomButton
                        disabled={this.state.saving}
                        icon={<CheckIcon />}
                        onClick={() => this.toggleBlacklist(record)}
                      >
                        {translate('app.action.toggleBlacklist')}
                      </CustomButton>);
                    }
                    return '';
                  }} />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    );
  }
}

export default translate(DocumentDetail);
