import Icon from '@material-ui/icons/Domain';

import EntitiesList from './EntitiesList';
import EntityDetail from './EntityDetail';

export default {
    name: 'entities',
    list: EntitiesList,
    show: EntityDetail,
    icon: Icon
};
