import React from 'react';
import { Provider } from 'react-redux';
import { Admin, Login, Resource, translate } from 'react-admin';

import routes from './routes';
import AppLayout from './layout';
import createStore from './store';

import authProvider from '../provider/authProvider';
import dataProvider from '../provider/dataProvider';

import entities from '../component/entities/index';
import users from '../component/users/index';
import documents from '../component/documents/index';
import documentsEmails from '../component/documents-emails/index';
import clients from '../component/clients/index';
import transactions from '../component/transactions/index';
import entityPlans from '../component/entity-plans/index';

import Dashboard from '../component/Dashboard';

const store = createStore();

const routesProps = (translate, props) => {
    return {
        context: 'route',
        options: { label: translate('app.label.' + props.name) },
        ...props
    };
};

const LoginPage = () => <Login backgroundImage="/background.jpg" className="login-background" />;

const App = ({ translate }) => (
  <Provider store={store.store}>
      <Admin
          title='Tu Facturero'
          dashboard={Dashboard}
          authProvider={authProvider}
          dataProvider={dataProvider}
          loginPage={LoginPage}
          appLayout={AppLayout}
          locale={store.resolveLocale()}
          i18nProvider={store.i18nProvider}
          history={store.history}
          customRoutes={routes}>
          <Resource {...routesProps(translate, entities)} />
          <Resource {...routesProps(translate, users)} />
          <Resource {...routesProps(translate, documents)} />
          <Resource {...routesProps(translate, documentsEmails)} />
          <Resource {...routesProps(translate, clients)} />
          <Resource {...routesProps(translate, transactions)} />
          <Resource {...routesProps(translate, entityPlans)} />
      </Admin>
  </Provider>
);

export default translate(App);
