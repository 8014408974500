import React from 'react';
import {
    translate
} from 'react-admin';

const DocumentTitle = translate(({ translate, record }) => (
    <span>{record ? `${translate('app.document.' + record.type)} "${record.establishment}-${record.emissionPoint}-${record.code}"` : ''}</span>
));

export default DocumentTitle;
