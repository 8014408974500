const map = {
  pichincha: 'pichincha',
  guayaquil: 'guayaquil',
  pacifico: 'pacifico',
  bolivariano: 'bolivariano',
};

const banks = Object.keys(map);
banks.asMap = map;

export default banks;