import React from 'react';
import {
    translate
} from 'react-admin';

const UserTitle = translate(({ translate, record }) => (
    <span>{record ? `${translate('app.label.user')} "${record.name}" - ${record.email}` : ''}</span>
));

export default UserTitle;
