import Icon from '@material-ui/icons/Note';

import DocumentsList from "./DocumentsList";
import DocumentDetail from "./DocumentDetail";

export default {
    name: 'documents',
    list: DocumentsList,
    show: DocumentDetail,
    icon: Icon
};
