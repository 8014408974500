import React from 'react';
import {
  translate,
  TextInput,
  Filter,
  DateInput,
  NullableBooleanInput,
  SelectInput,
} from 'react-admin';
import banks from "../../model/banks";

const trnStates = [
  'approved',
  'cancelled',
  'unauthorized',
  'rejected',
  'refunded',
  'unknown',
];

const vendors = [
  'transfer',
  'reseller',
  'touwolf',
  'stripe',
  'datafast',
  'paymentez',
  'cash',
  'credit_card',
  'debit_card',
  'unknown',
];

const TransactionFilter = translate((props) => {
  const { translate } = props;
  return <Filter {...props}>
    <TextInput
      label='app.action.search'
      source='q'
      alwaysOn />
    <DateInput
      label='app.label.from'
      source='from'
      alwaysOn />
    <DateInput
      label='app.label.to'
      source='to'
      alwaysOn />
    <NullableBooleanInput
      label='app.label.validated'
      source='validated'
      alwaysOn />
    <SelectInput
      label='app.label.bank'
      source='bank'
      alwaysOn
      choices={banks.map((bank) => {
        return { id: bank, name: translate(`app.bank.${bank}`) };
      })} />
    <SelectInput
      label='app.label.state'
      source='status'
      alwaysOn
      choices={trnStates.map((state) => {
        return { id: state, name: translate(`app.state.${state}`) };
      })} />
    <SelectInput
      label='app.label.vendor'
      source='vendor'
      alwaysOn
      choices={vendors.map((vendor) => {
        return { id: vendor, name: translate(`app.transaction.${vendor}`) };
      })} />
  </Filter>;
});

export default TransactionFilter;
