import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { createHashHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import {
  adminReducer,
  adminSaga,
  i18nReducer,
  formMiddleware,
  USER_LOGOUT,
  resolveBrowserLocale,
} from 'react-admin';

import englishMessages from '../i18n/english';
import spanishMessages from '../i18n/spanish';

import authProvider from '../provider/authProvider';
import dataProvider from '../provider/dataProvider';

import Config from '../Config';

export default () => {
  const resolveLocale = () => {
    const storedLocale = localStorage.getItem(Config.LOCALE_KEY);
    if (storedLocale) return storedLocale;
    const locale = resolveBrowserLocale();
    localStorage.setItem(Config.LOCALE_KEY, locale);
    return locale;
  };

  const messages = {
    es: spanishMessages,
    en: englishMessages,
  };

  const i18nProvider = locale => messages[ locale ];
  const history = createHashHistory();

  const reducer = combineReducers({
    admin: adminReducer,
    i18n: i18nReducer('en', i18nProvider('en')),
    router: routerReducer,
  });

  const resettableAppReducer = (state, action) => reducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const saga = function* rootSaga() {
    yield all([
      adminSaga(dataProvider, authProvider, i18nProvider),
    ].map(fork));
  };
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    resettableAppReducer,
    {},
    compose(
      applyMiddleware(
        sagaMiddleware,
        formMiddleware,
        routerMiddleware(history),
      ),
      typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );
  sagaMiddleware.run(saga);

  return {
    store: store,
    resolveLocale: resolveLocale,
    i18nProvider: i18nProvider,
    history: history,
  };
};
