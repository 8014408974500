import React from 'react';
import {
    Toolbar,
    SaveButton,
} from 'react-admin';

const DocumentEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export default DocumentEditToolbar;
