import englishMessages from 'ra-language-english';
import banks from "../model/banks";
import companies from '../model/companies';

const messages = {
  label: {
    phone: 'Phone',
    activeCoupon: 'Active coupon',
    emailBlackListed: 'Email is blacklisted',
    visible: 'Visible',
    entities: 'Entities',
    enabled: 'Enabled',
    users: 'Users',
    documents: 'Documents',
    configuration: 'Configuration',
    type: 'Type',
    code: 'Code',
    state: 'State',
    accessCode: 'Access Code',
    registered: 'Registered at',
    created: 'Created',
    expires: 'Expires',
    authorized: 'Authorized',
    usedAmount: 'Used',
    details: 'Details',
    id: 'ID',
    plans: 'Plans',
    name: 'Name',
    email: 'Email',
    entity: 'Entity',
    user: 'User',
    document: 'Document',
    company: 'Company',
    tasks: 'Tasks',
    executed: 'Executed',
    executesAt: 'Executes at',
    task: 'Task',
    clients: 'Clients',
    client: 'Client',
    from: 'From',
    to: 'To',
    transactions: 'Transactions',
    transaction: 'Transaction',
    vendor: 'Vendor',
    amount: 'Amount',
    error: 'Error',
    password: 'Password',
    days: 'Days',
    price: 'Price',
    coupon: 'Coupon',
    payment: 'Payment',
    documentsExpires: 'Documents expires at',
    signExpires: 'Sign expires at',
    todayStatistics: 'Today statistics',
    authorizedAt: 'Authorized at',
    notified: 'Notified',
    posAccess: 'POS Access',
    apiAccess: 'API Access',
    apiUser: 'API User',
    apiPassword: 'API Password',
    notes: 'Notes',
    rejectedReason: 'Rejected reason',
    emails: 'Emails',
    emailTo: 'To',
    lastEvent: 'Last event',
    blacklisted: 'Blacklisted',
    blacklistCategory: 'Blacklist Category',
    blacklistVendorType: 'Blacklist Vendor type',
    blacklistVendorSubtype: 'Blacklist Vendor Subtype',
    apiDocuments: 'API Documents',
    apiDocumentsExpires: 'API Documents expires at',
    sriMode: 'MODO SRI',
    signId: 'Sign ID',
    validated: 'Validated',
    bank: 'Bank',
    validateTransaction: 'Validate transaction',
    transactionId: 'Transaction ID',
    proofOfPayment: 'Proof of payment',
    proofOfPaymentDescription: 'Upload the proof of payment',
    hasFile: 'File',
    invoiceCode: 'Invoice code',
    isReseller: 'Reseller',
    touristRegistry: "Tourist registry",
    resellerDocuments: 'Reseller documents',
    resellerDocumentsExpiresAt: 'Reseller documents expires at',
    resellerCoupon: 'Reseller coupon',
    retentionCode: 'Retention code',
    retentionNumber: 'Retention sequential (XXX-YYY-ZZZZZZZZZ)',
    privacyPolicyAccepted: 'Privacy Policy Accepted',
    privacyPolicyDevice: 'Privacy Policy Device',
    privacyPolicyAt: 'Privacy Policy At',
    marketingCampaignAccepted: 'Marketing Campaign Accepted',
    marketingCampaignDevice: 'Marketing Campaign Device',
    marketingCampaignAt: 'Marketing Campaign At',
  },
  document: {
    receipt: 'Receipt',
    receipts: 'Receipts',
    bill: 'Invoice',
    bills: 'Invoices',
    preBill: 'Estimate',
    estimates: 'Estimates',
    creditNote: 'Credit Note',
    creditNotes: 'Credit Notes',
    retention: 'Retention',
    retentions: 'Retentions',
    remissionGuide: 'Remission Guide',
    remissionGuides: 'Remission Guides',
    purchaseClearance: 'Purchase Clearance',
  },
  state: {
    approved: 'Approved',
    draft: 'Draft',
    created: 'Created',
    sent: 'Sent',
    pendent: 'Pendent',
    unsent: 'Not Sent',
    inProcess: 'In Process',
    authorized: 'Authorized',
    rejected: 'Rejected',
    cancelled: 'Cancelled',
    unauthorized: 'Not Authorized',
    Send: 'Sent',
    opened: 'Opened',
    delivered: 'Delivered',
    Delivery: 'Delivered',
    bounce: 'Bounce',
    Bounce: 'Bounce',
    complaint: 'Complaint',
    refunded: 'Refunded',
    unknown: 'Unknown',
  },
  task: {
    documentNotify: 'Notify Document',
    documentSend: 'Send Document',
    documentUpdate: 'Update Document State',
  },
  transaction: {
    datafast: 'Datafast',
    paymentez: 'Paymentez',
    transfer: 'Transfer',
    cash: 'Cash',
    credit_card: 'Credit card',
    debit_card: 'Debit card',
    reseller: 'Reseller',
    touwolf: 'Touwolf',
    stripe: 'Stripe',
    unknown: 'Unknown',
  },
  bank: {
    [banks.asMap.pichincha]: 'Pichincha',
    [banks.asMap.guayaquil]: 'Guayaquil',
    [banks.asMap.pacifico]: 'Pacífico',
    [banks.asMap.bolivariano]: 'Bolivariano',
  },
  company: {
    [companies.asMap.eqon]: 'eQon',
    [companies.asMap.touwolf]: 'Touwolf',
  },
  action: {
    loginWeb: 'Login in web as user',
    toggleBlacklist: 'Toggle Blacklist',
    search: 'Search',
    save: 'Save',
    logInWeb: 'User WEB',
    add: 'Add',
    addDocuments: 'Add documents',
    addApiDocuments: 'Add API documents',
    ok: 'OK',
    cancel: 'Cancel',
    addThis: 'Add this',
    generateBill: 'Generate bill',
    posAccess: 'POS access',
    apiAccess: 'API access',
    addCustom: 'Add custom',
    send: 'Send',
    resendNonAuthDocuments: 'Re-send non authorized docs',
    refund: 'Refund',
    validate: 'Validate',
    seeFile: 'See file',
    setCouponReseller: 'Set reseller coupon',
    setInvoice: 'Set invoice',
    associateInvoice: 'Associate invoice',
    createInvoice: 'Create invoice',
    forReseller: 'For reseller',
    forApi: 'For API',
  },
  messages: {
    userCodeIsRequired: 'Enter user code.',
    entitySelectIsRequired: 'Select entity.',
    emailRemovedFromBlacklist: 'Email removed from blacklist',
    resellerCouponDescription: 'Owner user of entity will have a reseller profile with a coupon to share.',
    documentSaved: 'Document saved!',
    documentNotSaved: 'Document not saved!',
    taskSaved: 'Task saved!',
    taskNotSaved: 'Task not saved!',
    userSaved: 'User saved!',
    userNotSaved: 'User not saved!',
    invalidPassword: 'Password is not valid!',
    errorHappened: 'Error',
    availablePlans: 'Available plans',
    customPlan: 'Custom plan',
    invalidDocuments: 'Invalid documents',
    invalidPrice: 'Invalid price',
    invalidDays: 'Invalid days',
    entitySaved: 'Entity saved!',
    entityNotSaved: 'Entity not saved!',
    invalidTransactionState: 'Invalid transaction state',
    noFile: 'No file.',
    mustSearchAnAuthorizedDocument: 'You must search for an authorized document.',
    fromReseller: 'From reseller',
    invalidResellerId: 'Invalid reseller',
  },
  sriMode: {
    production: 'Production',
    sandbox: 'Test',
  },
};

export default { ...englishMessages, app: messages };
