import React from 'react';
import {
    translate,
    TabbedShowLayout,
    Tab,
    TextField,
    DateField,
    Show,
    EmailField,
} from 'react-admin';

import ClientTitle from './ClientTitle';
import EntityReference from "../entities/EntityReference";

const ClientDetail = ({ ...props }) => (
    <Show title={<ClientTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label='app.label.details'>
                <TextField label='app.label.id' source='legalId' />
                <TextField label='app.label.name' source='name' />
                <EmailField label='app.label.email' source='email' />
                <DateField
                    label='app.label.created'
                    source='createdAt'
                    options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} />
            </Tab>
            <EntityReference {...props} />
        </TabbedShowLayout>
    </Show>
);

export default translate(ClientDetail);
