import React from 'react';
import {
    translate,
    DateField,
    ReferenceField,
    TextField,
    Tab,
    EmailField,
} from 'react-admin';

const ClientReference = ({ ...props }) => (
    <Tab label='app.label.client' { ...props }>
        <ReferenceField label='app.label.id' reference='clients' source='clientId' linkType='show'>
            <TextField label='app.label.id' source='legalId' />
        </ReferenceField>
        <ReferenceField label='app.label.name' reference='clients' source='clientId' linkType={false}>
            <TextField label='app.label.name' source='name' />
        </ReferenceField>
        <ReferenceField label='app.label.email' reference='clients' source='clientId' linkType={false}>
            <EmailField label='app.label.email' source='email' />
        </ReferenceField>
        <ReferenceField label='app.label.created' reference='clients' source='clientId' linkType={false}>
            <DateField
                label='app.label.created'
                source='createdAt'
                options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }} />
        </ReferenceField>
    </Tab>
);

export default translate(ClientReference);
