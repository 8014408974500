
import React from 'react';

import { TextField, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = {
    touwolf: { color: 'green !important' },
    unknown: { color: 'gray !important' },
    datafast: { color: 'blue !important' },
    paymentez: { color: 'gray !important' },
    cash: { color: 'gray !important' },
    transfer: { color: 'orange !important' },
    reseller: { color: 'red !important' },
};

const TransactionVendorField = withStyles(styles)(
    ({ classes, translate, ...props }) => {
        const value = props.record[props.source];
        const fieldProps = {
            record: {
                [props.source]: translate('app.transaction.' + value)
            },
            source: props.source,
        };
        return <TextField
            className={classnames({
                [classes.touwolf]: value === 'touwolf',
                [classes.unknown]: value === 'unknown',
                [classes.datafast]: value === 'datafast',
                [classes.stripe]: value === 'datafast',
                [classes.paymentez]: value === 'paymentez',
                [classes.cash]: value === 'cash',
                [classes.transfer]: value === 'transfer',
                [classes.reseller]: value === 'reseller',
            })}
            {...fieldProps} />
    });

TransactionVendorField.defaultProps = TextField.defaultProps;

export default translate(TransactionVendorField);
