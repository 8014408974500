
const map = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    USER_SESSION_KEY: process.env.REACT_APP_USER_SESSION_KEY,
    LOCALE_KEY: process.env.REACT_APP_LOCALE_KEY,
    CONFIGURATION_ROUTE: process.env.REACT_APP_CONFIGURATION_ROUTE,
    ROOT_ENTITY_ID: process.env.REACT_APP_ROOT_ENTITY_ID,
};

export default map;
