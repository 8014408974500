import React from 'react';
import { NotificationManager } from 'react-notifications';
import {
  UPDATE,
  translate,
  SimpleForm,
  TabbedShowLayout,
  Tab,
  DateField,
  Show,
  ListButton,
  NumberField,
  TextField,
} from 'react-admin';

import TransactionStateField from './TransactionStateField';
import dataProvider from '../../provider/dataProvider';

import TransactionTitle from "./TransactionTitle";
import TransactionEditToolbar from "./TransactionEditToolbar";
import EntityReference from "../entities/EntityReference";
import UserReference from "../users/UserReference";
import TransactionVendorField from "./TransactionVendorField";
import TransactionBankField from "./TransactionBankField";
import TransactionInvoiceField from "./TransactionInvoiceField";

class TransactionDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {saving: false,};
        this.saveTransaction = this.saveTransaction.bind(this);
    }

    saveTransaction(record) {
        this.setState({
            saving: true,
        });
        const { translate } = this.props;
        dataProvider(UPDATE, 'transactions', { id: record.id, data: record })
            .then(() => {
                this.setState({
                    saving: false,
                });
                NotificationManager.success(translate('app.messages.transactionSaved'));
            })
            .catch((e) => {
                this.setState({
                    saving: false,
                });
                NotificationManager.error(translate('app.messages.transactionNotSaved'));
            });
    }

    render() {
        return (
          <Show {...this.props}
                title={<TransactionTitle/>}
                actions={<ListButton label='' />}>
            <TabbedShowLayout>
                <Tab label='app.label.details'>
                    <SimpleForm
                        toolbar={<TransactionEditToolbar translate={this.props.translate}/>}
                        saving={this.state.saving}
                        save={this.saveTransaction}>
                        <TransactionVendorField label='app.label.vendor' source='vendor' />
                        <TextField label='app.label.entity' source='entityName' />
                        <NumberField label='app.label.amount' source='amount' />
                        <TransactionStateField label='app.label.state' source='state' />
                        <TextField label='app.label.error' source='error' />
                        <TransactionBankField label='app.label.bank' source='bank' />
                        <TextField label='app.label.id' source='uniqueId' />
                        <TextField label='app.label.retentionCode' source='retentionCode' />
                        <TransactionInvoiceField label='app.document.bill' reference='documents' source='invoiceId' linkType="show"/>
                        <DateField
                            label='app.label.registered'
                            source='registered'
                            options={{weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'}}/>
                    </SimpleForm>
                </Tab>
                <EntityReference {...this.props} />
                <UserReference {...this.props} />
            </TabbedShowLayout>
          </Show>
        );
    }
}

export default translate(TransactionDetail);
